import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useStyles, iconStyles } from "../FilterDialogsStlyes";
import {
  SEARCH_CRITERIA_DISTINCT,
  SEARCH_CRITERIA_EQUAL,
  SEARCH_CRITERIA_LIKE,
} from "../../../../../util/Constants";

export default function FilterFieldTextDialog({
  open,
  handleFilterDialog,
  inheritedOperation,
  inheritedValue,
  deleteFilterConfig,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [operation, setOperation] = useState(SEARCH_CRITERIA_EQUAL);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    setFilterValue(inheritedValue);
  }, [inheritedValue]);

  useEffect(() => {
    if (inheritedOperation && inheritedOperation !== "") {
      setOperation(inheritedOperation);
    }
  }, [inheritedOperation]);

  function handleKeyPress(e) {
    if (e) {
      const { key } = e;
      if (key === "Enter") {
        handleFilterDialog(filterValue, false, operation);
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleFilterDialog(null, true)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography style={{ fontSize: "20px" }}>
          {t("SEARCH_FILTER_DEF_TITLE")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.filterDialogLine}>
          <Typography>{t("SEARCH_CRITERIA_LABEL")}</Typography>
          <Select
            value={operation}
            onChange={(e) => {
              setOperation(e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "operation select" }}
            style={{ width: "100%", marginLeft: 10 }}
          >
            <MenuItem value={SEARCH_CRITERIA_EQUAL}>
              {t("SEARCH_CRITERIA_EQUAL")}
            </MenuItem>
            <MenuItem value={SEARCH_CRITERIA_LIKE}>
              {t("SEARCH_CRITERIA_LIKE")}
            </MenuItem>
            <MenuItem value={SEARCH_CRITERIA_DISTINCT}>
              {t("SEARCH_CRITERIA_DISTINCT")}
            </MenuItem>
          </Select>
        </div>

        <div className={classes.filterDialogLine}>
          <TextField
            autoFocus={true}
            margin="dense"
            id="filterValue"
            label={t("SEARCH_CRITERIA_VALUE")}
            type="text"
            fullWidth
            value={filterValue}
            onChange={(evt) => {
              setFilterValue(evt.target.value);
            }}
            onKeyPress={handleKeyPress}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleFilterDialog(filterValue, false, operation)}
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {t("SEARCH_FILTER_APPLY")}
        </Button>
        {inheritedOperation && inheritedValue && (
          <IconButton
            aria-label={`remove filter button`}
            onClick={deleteFilterConfig}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {t("FORM_DELETE").toUpperCase()}
            <Icon
              className="fas fa-trash"
              style={{ ...iconStyles, marginLeft: 3 }}
            />
          </IconButton>
        )}
        <Button onClick={() => handleFilterDialog(null, true)} color="primary">
          {t("FORM_CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
