import React, { useState, useMemo } from "react";
import { Icon, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  isNil,
  isEmpty,
  isArray,
  trim,
  includes,
  filter,
  size,
  map,
  join,
} from "lodash";

import { commonIcon, useStyles } from "./FilterFieldStyles";
import { PaginatedMenu, isArrayOfArrays } from "./FilterFieldCombo";
import { GENERIC_ALL } from "../../../../util/gen-dashboard-constants";

export default function FilterFieldComboMultipleChoice({
  field,
  type,
  title,
  index,
  handleChangeFilterValue,
  comboOptions: filterComboOptions,
  owner,
  isLimit,
  canRemove,
  blockInteraction,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState([]);
  const [operation, setOperation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastValue, setLastValue] = useState("");

  function handleOpen(e) {
    setAnchorEl(e.currentTarget);
  }

  const comboOptions = useMemo(() => {
    if (!isNil(filterComboOptions) && !isEmpty(filterComboOptions)) {
      const hasArraysInside = isArrayOfArrays(filterComboOptions);
      if (hasArraysInside) {
        return filterComboOptions.map((x) => {
          if (!isNil(x) && !isEmpty(x) && isArray(x) && x.length >= 2) {
            return {
              value: trim(x[0]),
              label: trim(x[1]),
            };
          } else {
            return {
              value: trim(x),
              label: trim(x),
            };
          }
        });
      } else {
        return filterComboOptions.map((x) => {
          return {
            value: x,
            label: x,
          };
        });
      }
    } else {
      return [];
    }
  }, [filterComboOptions]);

  const filterKey = `${field}-${index}`;
  const hasToShowTooltipNoInfo = isNil(comboOptions) || isEmpty(comboOptions);

  const isFilterApplied = !isNil(value) && !isEmpty(value);

  const cantItems =
    !isNil(comboOptions) && !isEmpty(comboOptions)
      ? isFilterApplied === true
        ? ` (${size(value)}/${size(comboOptions)})`
        : ` (${size(comboOptions)})`
      : "";

  function handleFilterDialog(filterValue, cancel, operation) {
    if (!cancel) {
      if (filterValue === GENERIC_ALL) {
        setValue((prev) => {
          if (
            !isNil(prev) &&
            isArray(prev) &&
            size(prev) === size(comboOptions)
          ) {
            return [];
          } else {
            return comboOptions;
          }
        });
      } else {
        setValue((prev) => {
          if (includes(prev, filterValue)) {
            return filter(prev, function (o) {
              return o !== filterValue;
            });
          } else {
            return [...prev, filterValue];
          }
        });
      }

      setOperation(operation);
    }
  }

  function handleClose() {
    const areAllApplied =
      !isNil(value) &&
      isArray(value) &&
      !isNil(comboOptions) &&
      !isEmpty(comboOptions) &&
      size(comboOptions) === size(value);

    const valueToApply =
      !isNil(value) && isArray(value) ? join(map(value, "value"), ",") : "";

    // apply filters to give user the possibliy to select multiple and the apply
    setAnchorEl(null);

    if (valueToApply !== lastValue) {
      setLastValue(valueToApply);
      handleChangeFilterValue({
        value:
          valueToApply === "" || areAllApplied === true ? null : valueToApply,
        field,
        type,
        key: filterKey,
        operation,
        owner,
        isLimit,
      });
    }
  }

  function handleRemoveFilter() {
    setValue([]);
    setLastValue("");
    handleChangeFilterValue({
      value: null,
      field,
      type,
      key: filterKey,
      operation,
      owner,
      isLimit,
    });
    setAnchorEl(null);
  }

  return (
    <>
      <Tooltip
        title={t("QUERY_FILTERS_COMBO_NO_OPTIONS")}
        disableHoverListener={!hasToShowTooltipNoInfo}
      >
        <span>
          <button
            key={filterKey}
            className={
              hasToShowTooltipNoInfo
                ? classes.filterButtonDisabled
                : isFilterApplied
                ? classes.filterButtonWithValue
                : classes.filterButton
            }
            onClick={handleOpen}
            aria-controls={`${field}-${index}-combo`}
            disabled={hasToShowTooltipNoInfo || blockInteraction === true}
          >
            {isFilterApplied && (
              <Icon
                className="fas fa-filter"
                style={{ ...commonIcon, fontSize: "12px" }}
              />
            )}
            <Typography>
              {title} {cantItems}
            </Typography>
            {isFilterApplied &&
              canRemove !== false &&
              blockInteraction !== true && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFilter();
                  }}
                  className={classes.removeFilterButton}
                >
                  <Icon className="fas fa-times" style={commonIcon} />
                </IconButton>
              )}
          </button>
        </span>
      </Tooltip>

      <PaginatedMenu
        anchorEl={anchorEl}
        field={field}
        index={index}
        comboOptions={comboOptions}
        handleFilterDialog={handleFilterDialog}
        itemsPerPage={20}
        handleClose={handleClose}
        minOptionsToPaginate={20}
        isComboWithMultipleOutput={true}
        multipleValue={value}
      />
    </>
  );
}
