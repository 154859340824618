import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Fade,
  Hidden,
  Icon,
  IconButton,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isNil, isEmpty, isEqual, isString, toUpper } from "lodash";
import { useMsal } from "@azure/msal-react";
import useIsMounted from "react-is-mounted-hook";
import { Helmet } from "react-helmet";
import { useIsAuthenticated } from "@azure/msal-react";

import LoginParticles from "./LoginParticles";
import { useStyles, DARK_BLUE, commonIconStyles } from "./LoginStyles";
import { AuthContext } from "../../../core/providers/AuthContext";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import {
  ENV_PAGE_TITLE,
  HOME_TAB,
  HTTP_STATUS_TOO_MANY_REQUEST,
  IS_ENV_SELECTOR_ENABLED,
  IS_LOREAL_LOGIN_BUTTON_ENABLED,
  IS_NEW_LOGIN_ENABLED,
  TOAST_CONTAINER_LOGIN,
} from "../../../util/Constants";
import { LayoutContext } from "../../../core/providers/LayoutContext";
import { encryptPassword } from "../../../util/UtilFormat";
import { loginRequest } from "../../../core/sso/config/authConfig";
import { KEY_ENTER } from "../../../util/UtilKeys";
import DSuiteLogoNew from "../components/DSuiteLogoNew";
import MFADialog from "../components/MFADialog";
import ForgotPassDialog from "../../../components/dialogs/forgotPassDialog/ForgotPassDialog";
import { AccountPicker } from "./AccountPicker";
import StatusBadge from "../../../components/status/StatusBadge";
import GenericToastContainer from "../../../components/toast/GenericToastContainer";
import usePersistentStore from "../../../core/stores/PersistentStore";
import DSuiteEnvSelector from "../components/DSuiteEnvSelector";
import useEnvironmentStore from "../../../core/stores/EnvironmentStore";
import QAEnvDialog from "../components/QAEnvDialog";

export const LOGIN_TYPE_POPUP = "popup";
export const LOGIN_TYPE_REDIRECT = "redirect";

const containerId = TOAST_CONTAINER_LOGIN;

const COMPANY_SSO = "@LOREAL.COM";

function checkIfMailIsLorealAccount({ mail }) {
  if (process.env.NODE_ENV !== "development") {
    let result = false;
    if (!isNil(mail) && !isEmpty(mail)) {
      const domain = mail.substring(mail.indexOf("@"));
      if (!isNil(domain) && !isEmpty(domain) && isString(domain)) {
        result = isEqual(COMPANY_SSO, toUpper(domain));
      }
    }
    return result;
  } else {
    return true;
  }
}

export default function Login() {
  //Custom hooks
  const { t } = useTranslation();
  const history = useHistory();
  const wd = useWindowDimensions();
  const isMounted = useIsMounted();
  let { state } = useLocation();

  //MSAL and SSO
  const { instance, accounts } = useMsal();
  const [redirectingSSO, setRedirectingSSO] = useState(false);
  const [accountSelectorOpen, setOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const [lastWasSSO, setLastWasSSO] = useState(false);

  //Definitions and states
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [hasToWait, setHasToWait] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showMFADialog, setShowMFADialog] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [propagatedMustChangePass, setPropagatedMustChangePass] =
    useState(false);
  const [step, setStep] = useState(0);
  const [sending, setSending] = useState(false);
  const [showInputs, setShowInputs] = useState(!IS_LOREAL_LOGIN_BUTTON_ENABLED);
  const [showQADialog, setShowQADialog] = useState(
    process.env.REACT_APP_ENV === "QA"
  );

  const selectedEnvInfo = useEnvironmentStore((state) => state.selectedEnvInfo);

  const classes = useStyles({
    wd,
    isFull: IS_LOREAL_LOGIN_BUTTON_ENABLED !== true,
    selectedEnvInfo,
    isEnvEnabled: IS_ENV_SELECTOR_ENABLED === true,
  });

  //Context
  const {
    auth,
    login,
    loginMAD,
    validateMFA,
    forgotPassword,
    validateCaptcha,
  } = useContext(AuthContext);

  const throwToast = usePersistentStore((state) => state.throwToast);
  const counterToast = usePersistentStore((state) => state.counterToast);
  const setThrowToast = usePersistentStore((state) => state.setThrowToast);

  const { changeActiveTab } = useContext(LayoutContext);

  // ---------------------------------------
  // --- LOGIC
  // ---------------------------------------

  useEffect(() => {
    if (state?.fromLoginMad) {
      setLastWasSSO(true);
    }

    if (
      auth.isLogged === false &&
      isAuthenticated === true &&
      accounts &&
      accounts.length > 0 &&
      lastWasSSO
    ) {
      setTimeout(() => {
        processAzureLoginResponse({ account: accounts[0] });
      }, [200]);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, auth, accounts, history, state, lastWasSSO]);

  useEffect(() => {
    const keyPressEvent = (e) => {
      const { keyCode } = e;
      if (keyCode === KEY_ENTER && userName !== "" && password !== "") {
        processLogin();
      }
    };

    window.addEventListener("keydown", keyPressEvent);

    return () => {
      window.removeEventListener("keydown", keyPressEvent);
    };
    //eslint-disable-next-line
  }, [userName, password]);

  useEffect(() => {
    if (throwToast === true) {
      if (counterToast > 0) {
        toast.info(t("CLIENT_SESSION_EXPIRED"), {
          toastId: "session_expired",
          containerId: containerId,
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setThrowToast(false);
    }
    // eslint-disable-next-line
  }, []);

  // Handle auth
  useEffect(() => {
    if (auth?.isLogged && hasToWait) {
      setTimeout(() => {
        history.push("/");
      }, 1000);
    } else if (auth?.isLogged && hasToWait === false) {
      history.push("/");
    }

    return () => null;
  }, [auth, history, hasToWait]);

  // Process login and response
  async function processLogin() {
    setFetching(true);
    setHasToWait(true);
    const hashedPassword = encryptPassword(password);
    const loginResponse = await login({
      userName,
      password: hashedPassword,
      remember: true,
    });

    let toastContent = null;
    toast.dismiss();

    if (!isNil(loginResponse)) {
      const { ok, msg, mfaEnabled, mustChangePass } = loginResponse;
      if (ok) {
        const successLoginText = t("LOGIN_SUCCESS");
        toast.success(successLoginText, {
          containerId: containerId,
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        changeActiveTab(HOME_TAB);
        history.push("/");
      } else if (!ok && mfaEnabled) {
        setShowMFADialog(true);
        setPropagatedMustChangePass(mustChangePass);
      } else if (!ok && !isNil(msg)) {
        toastContent = msg;
      } else {
        toastContent = t("ERROR_RESOURCE_NOT_FOUND_TEXT");
      }
    } else {
      toastContent = t("ERROR_RESOURCE_NOT_FOUND_TEXT");
    }

    if (!isNil(toastContent)) {
      toast.error(toastContent, {
        containerId: containerId,
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setTimeout(() => {
      if (isMounted()) {
        setHasToWait(false);
        setFetching(false);
      }
    }, 1100);
  }

  const handleAzureLogin = async (loginType) => {
    if (loginType === LOGIN_TYPE_POPUP) {
      setRedirectingSSO(true);
      setHasToWait(true);
      const response = await instance.loginRedirect(loginRequest).catch(() => {
        if (isMounted()) {
          setHasToWait(false);
          setRedirectingSSO(false);
        }
      });
      processAzureLoginResponse(response);
    }
  };

  function processAzureLoginResponse(response) {
    if (response) {
      if (isMounted()) {
        setRedirectingSSO(true);
        setHasToWait(true);
      }
      const {
        account: { name, username, tenantId },
      } = response;
      if (name && username && tenantId) {
        if (checkIfMailIsLorealAccount({ mail: username })) {
          processLoginMAD({
            name,
            username: username.substring(0, username.indexOf("@")),
            mail: username,
            tenantId,
          });
        } else {
          toast.error(t("LOGIN_ERROR_SSO_NOT_LOREAL"), {
            containerId: containerId,
            position: "top-right",
            autoClose: false,
          });
          if (isMounted()) {
            setHasToWait(false);
            setRedirectingSSO(false);
          }
        }
      }
    } else {
      if (isMounted()) {
        setHasToWait(false);
        setRedirectingSSO(false);
      }
    }
  }

  // Process login and response
  async function processLoginMAD({ name, username, tenantId, mail }) {
    const loginResponse = await loginMAD({
      name,
      username,
      mail,
      tenantId,
      remember: true,
    });

    let toastContent = null;
    toast.dismiss();
    if (
      loginResponse &&
      !isNil(loginResponse.ok) &&
      loginResponse.ok === true
    ) {
      const successLoginText = t("LOGIN_SUCCESS");
      toast.success(successLoginText, {
        containerId: containerId,
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      changeActiveTab(HOME_TAB);
      history.push("/");
    } else if (
      loginResponse &&
      !isNil(loginResponse.ok) &&
      loginResponse.ok === false &&
      !isNil(loginResponse.msg)
    ) {
      toastContent = loginResponse.msg;
    } else {
      toastContent = t("ERROR_RESOURCE_NOT_FOUND_TEXT");
    }

    if (!isNil(toastContent)) {
      instance.setActiveAccount(null);
      toast.error(toastContent, {
        containerId: containerId,
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (isMounted()) {
      setHasToWait(false);
      setRedirectingSSO(false);
    }
  }

  const cancelMFA = useCallback(() => {
    setShowMFADialog(false);
  }, []);

  const handleMFA = useCallback(
    async ({ mfaCode }) => {
      const response = await validateMFA({
        userName,
        password: encryptPassword(password),
        mfaCode,
        remember: true,
        mustChangePass: propagatedMustChangePass,
      });

      if (response?.status === HTTP_STATUS_TOO_MANY_REQUEST) {
        const toastContent = response?.msg
          ? response?.msg
          : t("MFA_DIALOG_ERROR_CODE");
        toast.dismiss("validate-mfa-toast");
        toast.error(toastContent, {
          toastId: "validate-mfa-toast-429",
          containerId: containerId,
        });
      } else if (!response?.ok) {
        const toastContent = response?.msg
          ? response?.msg
          : t("MFA_DIALOG_ERROR_CODE");
        toast.dismiss("validate-mfa-toast-429");
        toast.error(toastContent, {
          toastId: "validate-mfa-toast",
          containerId: containerId,
        });
      }
    },
    [validateMFA, userName, password, propagatedMustChangePass, t]
  );

  const cancelForgotPass = useCallback(() => {
    setShowForgotPassword(false);
  }, []);

  async function invokeValidateCaptcha(params) {
    setSending(true);
    const response = await validateCaptcha(params);
    if (response) {
      const { ok, errorMsg } = response;
      if (ok) {
        setStep(1);
      } else {
        const errorToSet =
          !isNil(errorMsg) && !isEmpty(errorMsg)
            ? errorMsg
            : t("FORGOT_PASS_DIALOG_ERROR");
        toast.error(errorToSet, {
          containerId: containerId,
          autoClose: false,
        });
      }
    } else {
      toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
        containerId: containerId,
        autoClose: false,
      });
    }
    setSending(false);
  }

  async function invokeForgotPassword(params) {
    setSending(true);
    const response = await forgotPassword(params);
    if (response) {
      const { ok, errorMsg, status } = response;
      if (status === HTTP_STATUS_TOO_MANY_REQUEST) {
        toast.error(t("ERROR_TOO_MANY_REQUEST"), {
          containerId: containerId,
          autoClose: false,
        });
      } else {
        if (ok) {
          setStep(0);
          setShowForgotPassword(false);
          toast.success(t("FORGOT_PASS_DIALOG_SUCCESS"), {
            containerId: containerId,
            autoClose: false,
          });
        } else {
          const errorToSet =
            !isNil(errorMsg) && !isEmpty(errorMsg)
              ? errorMsg
              : t("FORGOT_PASS_DIALOG_ERROR");
          toast.error(errorToSet, {
            containerId: containerId,
            autoClose: false,
          });
        }
      }
    } else {
      toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
        containerId: containerId,
        autoClose: false,
      });
    }

    setSending(false);
  }

  const handleAccountSelection = () => {
    if (accounts && accounts.length > 0) {
      setOpen(true);
    } else {
      handleAzureLogin(LOGIN_TYPE_POPUP);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={IS_NEW_LOGIN_ENABLED === true ? classes.rootNew : classes.root}
    >
      <Helmet>
        <title>DSuite {ENV_PAGE_TITLE} Login</title>
      </Helmet>
      <GenericToastContainer containerId={containerId} />
      <MFADialog
        open={showMFADialog}
        handleAccept={handleMFA}
        handleCancel={cancelMFA}
      />
      <ForgotPassDialog
        open={showForgotPassword}
        handleCancel={cancelForgotPass}
        invokeForgotPassword={invokeForgotPassword}
        invokeValidateCaptcha={invokeValidateCaptcha}
        step={step}
        setStep={setStep}
        sending={sending}
      />

      <QAEnvDialog
        open={showQADialog === true}
        handleClose={() => setShowQADialog(false)}
      />
      {/* BACKGROUND PARTICLES */}
      {IS_NEW_LOGIN_ENABLED === false && (
        <div style={{ position: "absolute" }}>
          <LoginParticles />
        </div>
      )}
      {/* LOGIN DIALOG */}
      <div
        className={
          IS_NEW_LOGIN_ENABLED === true
            ? classes.loginBoxContainerNew
            : classes.loginBoxContainer
        }
      >
        <DSuiteLogoNew />
        {IS_ENV_SELECTOR_ENABLED === true ? <DSuiteEnvSelector /> : null}
        {/* INPUTS AND BUTTONS CONTAINER */}
        {IS_NEW_LOGIN_ENABLED === false ? (
          <div className={classes.body}>
            <Input
              id="login_user_input"
              key="username"
              value={userName}
              className={classes.inputsStyles}
              placeholder={"Username..."}
              type="text"
              onChange={(e) => {
                e.preventDefault();
                setUserName(e.target.value.toUpperCase());
              }}
              disableUnderline
              startAdornment={
                <InputAdornment position="start">
                  <Icon
                    className="fas fa-user-astronaut"
                    style={{
                      color: `${DARK_BLUE}BB`,
                      padding: 2,
                    }}
                  />
                </InputAdornment>
              }
            />

            <Input
              id="login_pass_input"
              key="password"
              value={password}
              className={classes.inputsStyles}
              type={showPassword ? "text" : "password"}
              placeholder={"Password..."}
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
              }}
              disableUnderline
              startAdornment={
                <InputAdornment position="start">
                  <Icon
                    className="fas fa-lock"
                    style={{ color: `${DARK_BLUE}BB`, padding: 2 }}
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? (
                      <Visibility style={{ color: DARK_BLUE }} />
                    ) : (
                      <VisibilityOff style={{ color: DARK_BLUE }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Button
              id="login_button"
              onClick={() => processLogin()}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.loginButton}
            >
              {fetching === true ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                <>
                  <Icon
                    className="fas fa-layer-group"
                    style={commonIconStyles}
                  />
                  <strong className={classes.loginButtonText}>
                    Login with DSuite
                  </strong>
                </>
              )}
            </Button>
            <button
              onClick={() => {
                setStep(0);
                setShowForgotPassword(true);
              }}
              className={classes.forgotPassButton}
            >
              Forgot your password?
            </button>
            {IS_LOREAL_LOGIN_BUTTON_ENABLED && (
              <Button
                id="login_mad_button"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.loginButtonMicrosoft}
                onClick={() => handleAccountSelection()}
                disabled={redirectingSSO === true}
              >
                {redirectingSSO === true ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <img
                      alt="microsoft-logo"
                      src={"/assets/img/microsoft-logo.svg"}
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                    <strong className={classes.loginButtonText}>
                      Login with Microsoft
                    </strong>
                  </>
                )}
              </Button>
            )}
            <StatusBadge showLabel />
            <AccountPicker
              open={accountSelectorOpen}
              onClose={handleClose}
              processAzureLoginResponse={processAzureLoginResponse}
            />
          </div>
        ) : (
          <div className={classes.bodyNew}>
            {IS_LOREAL_LOGIN_BUTTON_ENABLED && (
              <div className={classes.bodyNewOptionBox}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/LOREAL_light.png`}
                  className={classes.lorealImg}
                  alt="loreal-logo"
                />

                <Button
                  id="login_mad_button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.loginButtonMicrosoftNew}
                  onClick={() => handleAccountSelection()}
                  disabled={redirectingSSO === true}
                >
                  {redirectingSSO === true ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <img
                        alt="microsoft-logo"
                        src={"/assets/img/microsoft-logo.svg"}
                        style={{ width: 20, height: 20, marginRight: 10 }}
                      />
                      <strong className={classes.loginButtonText}>
                        Login Loreal Account
                      </strong>
                    </>
                  )}
                </Button>
              </div>
            )}

            <div
              className={
                IS_LOREAL_LOGIN_BUTTON_ENABLED
                  ? classes.bodyNewOptionBox
                  : classes.bodyNewOptionBoxFull
              }
            >
              {!showInputs && (
                <div className={classes.circleAstronaut}>
                  <Hidden xsDown>
                    <Icon
                      className="fas fa-user"
                      style={{
                        ...commonIconStyles,
                        marginRight: 0,
                        fontSize: 46,
                      }}
                    />
                  </Hidden>
                  <Hidden smUp>
                    <Icon
                      className="fas fa-user"
                      style={{
                        ...commonIconStyles,
                        marginRight: 0,
                        fontSize: 36,
                      }}
                    />
                  </Hidden>
                </div>
              )}

              {showInputs === true ? (
                <Fade in={true}>
                  <div className={classes.newInputsContainer}>
                    <div className={classes.inputsEnteringBox}>
                      <Input
                        id="login_user_input"
                        key="username"
                        value={userName}
                        className={classes.inputsStylesNew}
                        placeholder={"Username..."}
                        type="text"
                        onChange={(e) => {
                          e.preventDefault();
                          setUserName(e.target.value.toUpperCase());
                        }}
                        disableUnderline
                        startAdornment={
                          <InputAdornment position="start">
                            <Icon
                              className="fas fa-user"
                              style={{
                                color: `${DARK_BLUE}BB`,
                                padding: 2,
                              }}
                            />
                          </InputAdornment>
                        }
                        autoFocus={true}
                      />

                      <Input
                        id="login_pass_input"
                        key="password"
                        value={password}
                        className={classes.inputsStylesNew}
                        type={showPassword ? "text" : "password"}
                        placeholder={"Password..."}
                        onChange={(e) => {
                          e.preventDefault();
                          setPassword(e.target.value);
                        }}
                        disableUnderline
                        startAdornment={
                          <InputAdornment position="start">
                            <Icon
                              className="fas fa-lock"
                              style={{ color: `${DARK_BLUE}BB`, padding: 2 }}
                            />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? (
                                <Visibility style={{ color: DARK_BLUE }} />
                              ) : (
                                <VisibilityOff style={{ color: DARK_BLUE }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                    <Button
                      id="login_button"
                      onClick={() => processLogin()}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.loginButtonNew}
                    >
                      {fetching === true ? (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "white",
                          }}
                        />
                      ) : (
                        <>
                          <Icon
                            className="fas fa-layer-group"
                            style={commonIconStyles}
                          />
                          {!isNil(selectedEnvInfo) &&
                          IS_ENV_SELECTOR_ENABLED === true ? (
                            <strong className={classes.loginButtonText}>
                              Login ({selectedEnvInfo.label})
                            </strong>
                          ) : (
                            <strong className={classes.loginButtonText}>
                              Login
                            </strong>
                          )}
                        </>
                      )}
                    </Button>
                    <button
                      onClick={() => {
                        setStep(0);
                        setShowForgotPassword(true);
                      }}
                      className={classes.forgotPassButton}
                    >
                      Forgot your password?
                    </button>
                  </div>
                </Fade>
              ) : (
                <Button
                  id="login_new_button"
                  onClick={() => setShowInputs(true)}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.loginShowInputsBtn}
                >
                  <div className={classes.typoAndLock}>
                    <Icon className="fas fa-lock" style={commonIconStyles} />
                    <strong className={classes.loginButtonText}>
                      Login User/Password
                    </strong>
                  </div>
                </Button>
              )}
            </div>
            <StatusBadge showLabel />
            <AccountPicker
              open={accountSelectorOpen}
              onClose={handleClose}
              processAzureLoginResponse={processAzureLoginResponse}
            />
          </div>
        )}
      </div>
    </div>
  );
}
