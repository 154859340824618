import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, IconButton } from "@material-ui/core";

import FilterFieldButton from "./buttons/FilterFieldButton";
import FilterFieldCombo from "./buttons/FilterFieldCombo";

import {
  INPUT_TYPE_COMBOBOX,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_PASTE_AREA,
} from "../../../util/Constants";
import { clone, isArray, isEmpty, isNil } from "lodash";
import FilterFieldPasteArea from "./buttons/FilterFieldPasteArea";
import FilterFieldDateRange from "./buttons/FilterFieldDateRange";
import FilterFieldComboMultipleChoice from "./buttons/FilterFieldComboMultipleChoice";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: `${theme.palette.content.mainColor}33`,
    height: "auto",
    minHeight: 50,
    padding: 5,
    position: "relative",
    flexWrap: "wrap",
  },
  refreshButton: {
    position: "absolute",
    right: "5px",
  },
}));

export default function FilterFieldsContainer(props) {
  const { handleRefresh, availableFilters } = props;
  const classes = useStyles();
  const [filtersValues, setFiltersValues] = useState([]);

  function handleChangeFilterValue(filterValue) {
    let newFiltersValue = clone(filtersValues);
    if (!isNil(filterValue) && !isEmpty(filterValue) && isArray(filterValue)) {
      for (let i = 0; i < filterValue.length; i++) {
        const fv = filterValue[i];
        const { key } = fv;
        const isIn = newFiltersValue.findIndex((f) => f.key === key);
        if (isIn > -1) {
          newFiltersValue[isIn] = fv;
        } else {
          newFiltersValue.push(fv);
        }
      }
    } else {
      const { key } = filterValue;
      const isIn = newFiltersValue.findIndex((f) => f.key === key);
      if (isIn > -1) {
        newFiltersValue[isIn] = filterValue;
      } else {
        newFiltersValue.push(filterValue);
      }
    }
    setFiltersValues(newFiltersValue);
    handleRefresh(newFiltersValue);
  }

  return (
    <div className={classes.filtersContainer}>
      {availableFilters &&
        availableFilters.length > 0 &&
        availableFilters
          .filter((x) => isNil(x?.hidden) || x?.hidden === false)
          .map((f, index) => {
            const {
              field,
              title,
              type,
              input,
              comboOptions,
              owner,
              defaultValue,
              defaultOperation,
              isLimit,
              canRemove,
              injectsInto,
              canSelectMultiple,
              blockInteraction
            } = f;
            if (!isNil(input) && input === INPUT_TYPE_COMBOBOX) {
              const isComboWithMultipleOutput =
                !isNil(canSelectMultiple) && canSelectMultiple === true;

              if (isComboWithMultipleOutput) {
                return (
                  <FilterFieldComboMultipleChoice
                    key={`${field}-${index}`}
                    type={type}
                    title={title}
                    field={field}
                    index={index}
                    handleChangeFilterValue={handleChangeFilterValue}
                    comboOptions={comboOptions}
                    owner={owner}
                    defaultValue={defaultValue}
                    defaultOperation={defaultOperation}
                    isLimit={isLimit}
                    canRemove={canRemove}
                    injectsInto={injectsInto}
                    availableFilters={availableFilters}
                    blockInteraction={blockInteraction}
                  />
                );
              } else {
                return (
                  <FilterFieldCombo
                    key={`${field}-${index}`}
                    type={type}
                    title={title}
                    field={field}
                    index={index}
                    handleChangeFilterValue={handleChangeFilterValue}
                    comboOptions={comboOptions}
                    owner={owner}
                    defaultValue={defaultValue}
                    defaultOperation={defaultOperation}
                    isLimit={isLimit}
                    canRemove={canRemove}
                    injectsInto={injectsInto}
                    availableFilters={availableFilters}
                    blockInteraction={blockInteraction}
                  />
                );
              }
            } else if (!isNil(input) && input === INPUT_TYPE_PASTE_AREA) {
              return (
                <FilterFieldPasteArea
                  key={`${field}-${index}`}
                  type={type}
                  title={title}
                  field={field}
                  index={index}
                  handleChangeFilterValue={handleChangeFilterValue}
                  owner={owner}
                  defaultValue={defaultValue}
                  defaultOperation={defaultOperation}
                  isLimit={isLimit}
                  canRemove={canRemove}
                  injectsInto={injectsInto}
                  availableFilters={availableFilters}
                  blockInteraction={blockInteraction}
                />
              );
            } else if (!isNil(input) && input === INPUT_TYPE_DATE_RANGE) {
              return (
                <FilterFieldDateRange
                  key={`${field}-${index}`}
                  type={type}
                  title={title}
                  field={field}
                  index={index}
                  handleChangeFilterValue={handleChangeFilterValue}
                  owner={owner}
                  defaultValue={defaultValue}
                  defaultOperation={defaultOperation}
                  isLimit={isLimit}
                  canRemove={canRemove}
                  injectsInto={injectsInto}
                  availableFilters={availableFilters}
                  blockInteraction={blockInteraction}
                />
              );
            } else {
              return (
                <FilterFieldButton
                  key={`${field}-${index}`}
                  type={type}
                  title={title}
                  field={field}
                  index={index}
                  handleChangeFilterValue={handleChangeFilterValue}
                  owner={owner}
                  defaultValue={defaultValue}
                  defaultOperation={defaultOperation}
                  isLimit={isLimit}
                  canRemove={canRemove}
                  injectsInto={injectsInto}
                  availableFilters={availableFilters}
                  blockInteraction={blockInteraction}
                />
              );
            }
          })}
      <IconButton
        onClick={() => handleRefresh(filtersValues)}
        className={classes.refreshButton}
      >
        <Icon className="fas fa-sync" style={commonIcon} />
      </IconButton>
    </div>
  );
}
