import React, { useMemo, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Checkbox,
  Input,
  ListItemText,
  Select,
  Tooltip,
} from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";

import { StyledInput } from "../table/styles";
import {
  getCorrectStyleVersionToUse,
  QUERIES_STYLE_VERSION_OLD,
} from "../util-reports";

const MAX_SELECT_ITEMS = 200;
const BTN_ADD_MORE_ROWS = "btn-add-more";

export function handlerColumnFilter({
  column,
  hasToBlockIndividualFiltersByLimit,
  stylesVersion,
}) {
  switch (column.type) {
    case "boolean":
    case "string":
    case "numeric":
      return DefaultColumnFilter({
        column,
        hasToBlockIndividualFiltersByLimit,
        stylesVersion,
      });
    case "date":
    case "datetime":
      return SelectColumnFilter({
        column,
        hasToBlockIndividualFiltersByLimit,
        stylesVersion,
      });
    default:
      return DefaultColumnFilter({
        column,
        hasToBlockIndividualFiltersByLimit,
        stylesVersion,
      });
  }
}

export function DefaultColumnFilter({
  column,
  hasToBlockIndividualFiltersByLimit,
  stylesVersion,
}) {
  const { filterValue, setFilter } = column;

  return (
    <FilterMenu
      column={column}
      hasToBlockIndividualFiltersByLimit={hasToBlockIndividualFiltersByLimit}
      stylesVersion={stylesVersion}
    >
      <StyledInput
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        disabled={hasToBlockIndividualFiltersByLimit}
      />
    </FilterMenu>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function SelectColumnFilter({
  column,
  hasToBlockIndividualFiltersByLimit,
  stylesVersion,
}) {
  const { t } = useTranslation();
  const { filterValue, setFilter, preFilteredRows, id } = column;
  const [maxSize, setMaxSize] = useState(MAX_SELECT_ITEMS);

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      const valToAdd = row?.values[id];
      options.add(_.isString(valToAdd) ? valToAdd?.trim() : valToAdd);
    });
    const arr = Array.from(options.values());
    return arr.sort();
  }, [id, preFilteredRows]);

  const selectFilterValue = useMemo(() => {
    if (
      !_.isNil(filterValue) &&
      !_.isEmpty(filterValue) &&
      _.isArray(filterValue)
    ) {
      return filterValue;
    } else {
      return [];
    }
  }, [filterValue]);

  function handleChange(e) {
    const arrayToSet = e.target.value;
    e.preventDefault();
    e.stopPropagation();
    const lastElement = _.last(arrayToSet);
    if (
      !_.isNil(lastElement) &&
      !_.isEmpty(lastElement) &&
      lastElement === BTN_ADD_MORE_ROWS
    ) {
      return chargeMore();
    } else {
      if (
        !_.isNil(arrayToSet) &&
        !_.isEmpty(arrayToSet) &&
        _.isArray(arrayToSet) &&
        arrayToSet.includes("")
      ) {
        // all toggled
        setFilter(undefined);
      } else {
        setFilter(arrayToSet || undefined);
      }
    }
  }

  const sortedOptions = useMemo(() => {
    if (!_.isNil(options) && !_.isEmpty(options)) {
      return options.sort(
        (a, b) =>
          !_.isNil(selectFilterValue) &&
          !_.isEmpty(selectFilterValue) &&
          _.isArray(selectFilterValue) &&
          selectFilterValue.indexOf(a) > -1 &&
          selectFilterValue.reverse().indexOf(b) -
            selectFilterValue.reverse().indexOf(a)
      );
    } else {
      return options;
    }
  }, [options, selectFilterValue]);

  function chargeMore() {
    setMaxSize((prev) => prev + MAX_SELECT_ITEMS);
  }

  return (
    <FilterMenu
      column={column}
      hasToBlockIndividualFiltersByLimit={hasToBlockIndividualFiltersByLimit}
      stylesVersion={stylesVersion}
    >
      <Select
        value={selectFilterValue}
        onChange={handleChange}
        style={{ width: "100%" }}
        multiple
        input={<Input />}
        renderValue={(selected) => {
          if (_.isArray(selected) && selected.length > 1) {
            return t("REPORTS_MULTIPLE_FILTER_APPLIED", { q: selected.length });
          } else {
            return selected.join(", ");
          }
        }}
        MenuProps={MenuProps}
        disabled={hasToBlockIndividualFiltersByLimit}
      >
        <MenuItem value="">{t("RECORDS_PANEL_GROUPING_ALL")}</MenuItem>
        {sortedOptions.slice(0, maxSize).map((option, i) => (
          <MenuItem key={i} value={option}>
            <Checkbox
              checked={
                !_.isNil(selectFilterValue) &&
                !_.isEmpty(selectFilterValue) &&
                _.isArray(selectFilterValue) &&
                selectFilterValue.indexOf(option) > -1
              }
            />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
        {sortedOptions.length > MAX_SELECT_ITEMS && (
          <MenuItem value={BTN_ADD_MORE_ROWS}>
            {t("REPORTS_FILTER_SELECT_LOAD_MORE")}
          </MenuItem>
        )}
      </Select>
    </FilterMenu>
  );
}

export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  hasToBlockIndividualFiltersByLimit,
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
        disabled={hasToBlockIndividualFiltersByLimit}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

export function NumberRangeColumnFilter({
  column,
  hasToBlockIndividualFiltersByLimit,
  stylesVersion,
}) {
  const { filterValue = [], setFilter, preFilteredRows, id } = column;
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <FilterMenu
      column={column}
      hasToBlockIndividualFiltersByLimit={hasToBlockIndividualFiltersByLimit}
      stylesVersion={stylesVersion}
    >
      <div style={{ display: "flex" }}>
        <StyledInput
          value={filterValue[0] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: "70px",
            marginRight: "0.5rem",
          }}
          disabled={hasToBlockIndividualFiltersByLimit}
        />
        <StyledInput
          value={filterValue[1] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: "70px",
            marginLeft: "0.5rem",
          }}
          disabled={hasToBlockIndividualFiltersByLimit}
        />
      </div>
    </FilterMenu>
  );
}

export function DateRangeColumnFilter({
  column,
  hasToBlockIndividualFiltersByLimit,
  stylesVersion,
}) {
  const { filterValue = [], setFilter, preFilteredRows, id } = column;

  const [min, max] = React.useMemo(() => {
    let min = new Date(preFilteredRows[0].values[id]);
    let max = new Date(preFilteredRows[0].values[id]);
    preFilteredRows.forEach((row) => {
      min = new Date(row.values[id]) <= min ? new Date(row.values[id]) : min;
      max = new Date(row.values[id]) >= max ? new Date(row.values[id]) : max;
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <FilterMenu
      column={column}
      hasToBlockIndividualFiltersByLimit={hasToBlockIndividualFiltersByLimit}
      stylesVersion={stylesVersion}
    >
      <div style={{ display: "flex" }}>
        <StyledInput
          value={filterValue[0] || ""}
          type="date"
          min={min.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? val : undefined, old[1]]);
          }}
          style={{
            marginRight: "0.5rem",
          }}
          disabled={hasToBlockIndividualFiltersByLimit}
        />
        -
        <StyledInput
          value={filterValue[1] || ""}
          type="date"
          max={max.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? val : undefined]);
          }}
          style={{
            marginLeft: "0.5rem",
          }}
          disabled={hasToBlockIndividualFiltersByLimit}
        />
      </div>
    </FilterMenu>
  );
}

function FilterMenu({
  children,
  column,
  hasToBlockIndividualFiltersByLimit,
  stylesVersion,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilter = (function () {
    return {
      input: function () {
        column.filter = "fuzzyText";
        column.Filter = DefaultColumnFilter;
        //hack to render column again
        column.setFilter(undefined);
        setAnchorEl(null);
      },
      select: function () {
        column.filter = "includesMultiple";
        column.Filter = SelectColumnFilter;
        column.setFilter(undefined);
        setAnchorEl(null);
      },
      between: function () {
        column.filter = "between";
        column.Filter = NumberRangeColumnFilter;
        column.setFilter(undefined);
        setAnchorEl(null);
      },
      date: function () {
        column.filter = "dateBetween";
        column.Filter = DateRangeColumnFilter;
        column.setFilter(undefined);
        setAnchorEl(null);
      },
    };
  })();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Tooltip
      title={
        hasToBlockIndividualFiltersByLimit === false
          ? ""
          : t("REPORTS_FILTERS_DISABLES_LIMIT")
      }
      placement="right"
      disableHoverListener={hasToBlockIndividualFiltersByLimit === false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {children}
        <IconButton
          aria-label="filter"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
          disabled={hasToBlockIndividualFiltersByLimit}
        >
          {getCorrectStyleVersionToUse({ stylesVersion }) ===
          QUERIES_STYLE_VERSION_OLD ? (
            <FilterListIcon />
          ) : (
            <TuneIcon />
          )}
        </IconButton>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "30ch",
            },
          }}
        >
          <MenuItem key="filter-input" onClick={handleFilter.input}>
            {t("REPORTS_FILTER_TEXT")}
          </MenuItem>
          <MenuItem key="filter-select" onClick={handleFilter.select}>
            {t("REPORTS_FILTER_SELECT")}
          </MenuItem>
          {column.type === "numeric" && (
            <MenuItem key="filter-between" onClick={handleFilter.between}>
              {t("REPORTS_FILTER_RANGE")}
            </MenuItem>
          )}
          {(column.type === "date" || column.type === "datetime") && (
            <MenuItem key="filter-data" onClick={handleFilter.date}>
              {t("REPORTS_FILTER_DATES")}
            </MenuItem>
          )}
        </Menu>
      </div>
    </Tooltip>
  );
}

export default React.memo(FilterMenu);
