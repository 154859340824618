import React, { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { endOfMonth, format, isValid, parse, startOfYear } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  Popper,
  Fade,
  Button,
  Box,
  useTheme,
  Icon,
  Typography,
  IconButton,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useTranslation } from "react-i18next";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";

import { useLocale } from "../../../../core/hooks/useLocale";
import useDefaultStaticRanges from "../../../../core/hooks/useDefaultStaticRanges";
import { has, isEmpty, isEqual, isNil, isObject } from "lodash";
import {
  DATE_PATTERN,
  FRONT_DATE_PATTERN,
  SEARCH_CRITERIA_BETWEEN,
} from "../../../../util/Constants";

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const commonFilter = {
  border: "none",
  borderRadius: 5,
  padding: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: "#FFFFFF",
  height: 30,
  marginRight: 12,
  marginTop: 8,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    borderRadius: "10px",
    border: "1px solid #f0f0f0",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    backgroundColor: theme.palette.background.paper,
  },
  buttonLarge: {
    padding: 7,
  },
  buttonLargeSelected: {
    padding: 7,
    borderLeft: `5px solid ${theme.palette.content.mainColor}`,
    borderRight: `5px solid ${theme.palette.content.mainColor}`,
    borderRadius: 10,
  },
  datePicker: {
    "& .rdrCalendarWrapper": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    "& .rdrDateDisplayWrapper": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    "& .rdrDateInput": {
      backgroundColor: theme.palette.background.paper,
      "& input": {
        color: theme.palette.text.primary,
      },
    },
    "& .rdrMonthAndYearWrapper": {
      "& select": {
        color: theme.palette.primary.main,
      },
    },
    "& .rdrDefinedRangesWrapper": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRight: "solid 1px " + theme.palette.divider,
    },
    "& .rdrStaticRange": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderBottom: "solid 1px " + theme.palette.divider,
    },
    "& .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel":
      {
        backgroundColor: theme.palette.text.hover,
        borderBottom: "none",
      },
    backgroundColor: theme.palette.background.paper,
  },
  filterButton: {
    ...commonFilter,
    backgroundColor: `${theme.palette.content.mainColor}99`,
  },
  filterButtonWithValue: {
    ...commonFilter,
    border: "2px solid #FFFFFF",
    backgroundColor: `${theme.palette.content.mainColor}99`,
  },
  removeFilterButton: {
    padding: 2,
    marginLeft: 6,
    color: "darkorange",
  },
}));

const DateRange = ({ defaultDate, range, setRange }) => {
  const [locale] = useLocale();
  const theme = useTheme();
  const classes = useStyles();
  const staticRanges = useDefaultStaticRanges(defaultDate);

  return (
    <DateRangePicker
      direction="vertical"
      inputRanges={[]}
      locale={locales[locale]}
      months={1}
      moveRangeOnFirstSelection={false}
      ranges={[range]}
      showSelectionPreview={true}
      staticRanges={staticRanges}
      onChange={(item) => setRange(item.selection)}
      className={classes.datePicker}
      color={theme.palette.primary.main}
      rangeColors={[theme.palette.primary.main]}
    />
  );
};

const initial = {
  date_from: null,
  date_to: null,
  initial: true,
};

export default function FilterFieldDateRange({
  field,
  type,
  title,
  index,
  handleChangeFilterValue,
  owner,
  isLimit,
  canRemove,
  defaultValue,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState(initial);

  const filterKey = `${field}-${index}`;

  const defaultDate = {
    startDate: startOfYear(new Date("2022-12-31")),
    endDate: endOfMonth(new Date()),
  };

  const [range, setRange] = useState({
    ...defaultDate,
    key: "selection",
  });

  const handleOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    setFilters((filters) => ({
      ...filters,
      date_from: range.startDate,
      date_to: range.endDate,
      initial: false,
    }));

    const dateFromVal = format(range.startDate, DATE_PATTERN);
    const dateToVal = format(range.endDate, DATE_PATTERN);

    handleChangeFilterValue({
      value: dateFromVal,
      valueTwo: dateToVal,
      field,
      type,
      key: filterKey,
      operation: SEARCH_CRITERIA_BETWEEN,
      owner,
      isLimit,
      omitDefaultValues: true,
    });
  };

  const deleteFilterConfig = () => {
    setFilters({ ...initial, initial: false });
    handleChangeFilterValue({
      value: null,
      valueTwo: null,
      field,
      type,
      key: filterKey,
      operation: null,
      owner,
      isLimit,
      omitDefaultValues: true,
    });
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  const isFiltersApplied = useMemo(() => {
    return !isNil(filters?.date_from) && !isNil(filters?.date_to);
  }, [filters]);

  useEffect(() => {
    if (
      !isNil(defaultValue) &&
      !isEmpty(defaultValue) &&
      isObject(defaultValue) &&
      has(defaultValue, "from") &&
      has(defaultValue, "to") &&
      !isNil(defaultValue["from"]) &&
      !isEmpty(defaultValue["from"]) &&
      !isNil(defaultValue["to"]) &&
      !isEmpty(defaultValue["to"]) &&
      isValid(parse(defaultValue["from"], DATE_PATTERN, new Date())) &&
      isValid(parse(defaultValue["to"], DATE_PATTERN, new Date())) &&
      isEqual(filters, initial)
    ) {
      const fromBase = defaultValue["from"];
      const toBase = defaultValue["to"];

      const fromToSet = parse(fromBase, DATE_PATTERN, new Date());
      const toToSet = parse(toBase, DATE_PATTERN, new Date());

      const defaultRangeToSet = {
        date_from: fromToSet,
        date_to: toToSet,
        initial: false,
      };

      setFilters(defaultRangeToSet);
    }
  }, [defaultValue, filters]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <button
          key={filterKey}
          className={
            isFiltersApplied
              ? classes.filterButtonWithValue
              : classes.filterButton
          }
          onClick={handleOpen}
        >
          {isFiltersApplied && canRemove !== false && (
            <Icon
              className="fas fa-filter"
              style={{ ...commonIcon, fontSize: "12px" }}
            />
          )}
          <Typography>
            {filters.date_from && filters.date_to
              ? `${format(filters.date_from, FRONT_DATE_PATTERN)} - ${format(
                  filters.date_to,
                  FRONT_DATE_PATTERN
                )}`
              : title}
          </Typography>
          {isFiltersApplied && canRemove !== false && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteFilterConfig();
              }}
              className={classes.removeFilterButton}
            >
              <Icon className="fas fa-times" style={commonIcon} />
            </IconButton>
          )}
        </button>

        <Popper
          transition
          anchorEl={anchorEl}
          className={classes.paper}
          id={id}
          open={open}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box className={classes.box}>
                <DateRange
                  defaultDate={defaultDate}
                  range={range}
                  setRange={setRange}
                />
                <Button color="primary" onClick={handleClose}>
                  {t("DATE_RANGE_ACTION_FILTER")}
                </Button>
              </Box>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
