import React, { useMemo, useState } from "react";
import clsx from "clsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import GlobalFilter from "./GlobalFilter";
import IconButton from "@material-ui/core/IconButton";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import TableToolbarOptions from "./TableToolbarOptions";
import * as UtilExcel from "../../../util/UtilExcel";
import {
  API_FORM_QUERY,
  METHOD_POST,
  SERVER_HOST,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { isArray, isEmpty, isNil, isNumber, toString, trim } from "lodash";
import { CircularProgress } from "@material-ui/core";
import { sortArrayByArrayValues } from "../../../util/UtilFormat";
import { useReportsStore } from "../../../core/stores/ReportsStore";
import GlobalActionSelect from "./GlobalActionSelect";
import {
  getCorrectStyleVersionToUse,
  QUERIES_STYLE_VERSION_OLD,
} from "../util-reports";

const TABLE_GROUPED_EXPORT_EXCEL_MESSAGE =
  "No es posible exportar a Excel si hay agrupaciones.";

const TABLE_EXPORT_EXCEL_ERROR =
  "No fue posible exportar a Excel. Contacte a soporte";

const TYPE_SELECTED = "SEL";
const TYPE_ALL = "ALL";

const MAX_EXPORT_EXCEL_ROWS = 150 * 1000; // 150K by default

const EXPORT_EXCEL_TOAST_TIME = 600000 * 3; //30 minutes, 600000 = 10 minutes

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  titleV1: {
    flex: "1 1 100%",
    marginLeft: 10,
  },
  title: {
    flex: "1 1 100%",
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    color: `${theme.palette.content.mainColor}`,
    fontWeight: "bold",
    "&::before": {
      content: '""',
      width: 10,
      marginRight: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.content.mainColor}AA`,
    },
    "&::after": {
      content: '""',
      flex: 1,
      marginLeft: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.content.mainColor}AA`,
    },
  },
  downloadExcelBtn: {
    marginLeft: 15,
  },
  rootMenu: {
    border: "2px solid",
  },
}));

const TableToolbar = (props) => {
  const { t } = useTranslation();
  const classes = useToolbarStyles();
  const {
    numSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    title,
    selectedFlatRows,
    getToggleAllRowsExpandedProps,
    isAllRowsExpanded,
    isTableGrouped,
    allColumns,
    keyReport,
    queryFilters,
    selectedTab,
    hasToBlockIndividualFiltersByLimit,
    isMaterializedQuery,
    handleFilterMaterializedQuery,
    countRows,
    overrideMaxExcelRows,
    lastSearch,
    handleRefreshCurrent,
    lastModDateMaterialized,
    keyToAsk,
    hideToolbarOptions,
    globalActions,
    coordinationFilters,
    stylesVersion,
  } = props;
  const [exporting, setExporting] = useState(false);

  const REQUEST_HEADERS = useRequestHeaders();
  const sColumnsOrder = useReportsStore((state) => state.sColumnsOrder);

  const handleDownloadExcel = async (type) => {
    const toastIdToUse = `export-excel-type-${type}`;
    const mustOrderColumns =
      !isNil(sColumnsOrder) &&
      !isNil(sColumnsOrder?.get(keyToAsk)) &&
      isArray(sColumnsOrder?.get(keyToAsk)) &&
      !isEmpty(sColumnsOrder?.get(keyToAsk));

    setExporting(true);
    if (!isTableGrouped) {
      const baseColumns = mustOrderColumns
        ? sortArrayByArrayValues(
            allColumns,
            sColumnsOrder?.get(keyToAsk),
            "field"
          )
        : allColumns;

      // Based on type
      if (TYPE_SELECTED === type) {
        const showingColumns = baseColumns
          .filter((c) => c.canGroupBy && !isNil(c.field) && c?.isVisible)
          .map((c) => {
            return {
              name: c?.title,
              value: c?.id,
              type: c?.type,
              mask: c?.mask,
            };
          });

        const data = selectedFlatRows.map((row) => row.values || row.original);
        UtilExcel.downloadExcelUniqueSheet({
          data: data,
          columns: showingColumns,
          fileName: title,
          sheetName: title,
        });
      } else if (TYPE_ALL === type) {
        const showingColumns = baseColumns
          .filter((c) => c.canGroupBy && !isNil(c.field) && c?.isVisible)
          .map((c) => {
            return {
              title: c?.title,
              field: c?.field,
            };
          });

        toast.info(t("EXCEL_DOWNLOAD_TAKES_MINUTES"), {
          toastId: toastIdToUse,
          containerId: TOAST_CONTAINER_LAYOUT,
          autoClose: EXPORT_EXCEL_TOAST_TIME,
        });
        const url = `${SERVER_HOST()}${API_FORM_QUERY}/export/excel?tab=${selectedTab}`;
        const body = {
          idForm: keyReport,
          queryFilters,
          showingColumns,
        };
        const response = await fetch(url, {
          body: JSON.stringify(body),
          method: METHOD_POST,
          headers: REQUEST_HEADERS,
        });
        const data = await response.json();
        if (!isNil(data)) {
          const { dataResponse, ok, errorMsg } = data;
          if (
            ok === true &&
            (isNil(errorMsg) || trim(toString(errorMsg)) === "")
          ) {
            if (
              !isNil(dataResponse) &&
              !isEmpty(dataResponse) &&
              !isNil(dataResponse.url)
            ) {
              const fileNameToDownload = `${title}.xlsx`;
              saveAs(dataResponse.url, fileNameToDownload);
            }
          } else if (ok === true && !isNil(errorMsg)) {
            toast.dismiss(toastIdToUse);
            toast.warn(errorMsg, {
              containerId: TOAST_CONTAINER_LAYOUT,
              autoClose: false,
              theme: "light",
            });
          } else {
            toast.error(errorMsg || TABLE_EXPORT_EXCEL_ERROR, {
              containerId: TOAST_CONTAINER_LAYOUT,
            });
          }
        } else {
          toast.error(TABLE_EXPORT_EXCEL_ERROR, {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
      }
    } else {
      toast.warn(TABLE_GROUPED_EXPORT_EXCEL_MESSAGE, {
        containerId: TOAST_CONTAINER_LAYOUT,
      });
    }

    setTimeout(() => {
      toast.dismiss(toastIdToUse);
      setExporting(false);
    }, [100]);
  };

  const maxExcelRows = useMemo(() => {
    return !isNil(overrideMaxExcelRows) &&
      isNumber(overrideMaxExcelRows) &&
      overrideMaxExcelRows > 0
      ? overrideMaxExcelRows
      : MAX_EXPORT_EXCEL_ROWS;
  }, [overrideMaxExcelRows]);

  const isExportDisabledByTotalRows = useMemo(() => {
    let result = false;
    if (
      !isNil(countRows) &&
      !isNil(maxExcelRows) &&
      isNumber(countRows) &&
      isNumber(maxExcelRows)
    ) {
      result = countRows > maxExcelRows;
    }

    return result;
  }, [countRows, maxExcelRows]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {t("REPORTS_SELECTED", { q: numSelected })}
        </Typography>
      ) : !isNil(lastModDateMaterialized) &&
        !isEmpty(lastModDateMaterialized) ? (
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
          <Typography variant="body2" id="lastUpdate">
            {t("REPORTS_LAST_UPDATE", { lastUpdate: lastModDateMaterialized })}
          </Typography>
        </div>
      ) : (
        <Typography
          className={
            getCorrectStyleVersionToUse({ stylesVersion }) ===
            QUERIES_STYLE_VERSION_OLD
              ? classes.titleV1
              : classes.title
          }
          variant="h6"
          id="tableTitle"
        >
          {title}
        </Typography>
      )}

      <>
        {hideToolbarOptions !== true && (
          <TableToolbarOptions
            getToggleAllRowsExpandedProps={getToggleAllRowsExpandedProps}
            isAllRowsExpanded={isAllRowsExpanded}
          />
        )}

        <GlobalActionSelect
          keyReport={keyReport}
          globalActions={globalActions}
          handleRefreshCurrent={handleRefreshCurrent}
          selectedTab={selectedTab}
          coordinationFilters={coordinationFilters}
        />

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          hasToBlockIndividualFiltersByLimit={
            hasToBlockIndividualFiltersByLimit
          }
          isMaterializedQuery={isMaterializedQuery}
          handleFilterMaterializedQuery={handleFilterMaterializedQuery}
          lastSearch={lastSearch}
          handleRefreshCurrent={handleRefreshCurrent}
        />
        <Tooltip
          title={
            isTableGrouped
              ? TABLE_GROUPED_EXPORT_EXCEL_MESSAGE
              : isExportDisabledByTotalRows
              ? t("REPORTS_CANT_EXPORT_MAX_ROWS", {
                  countRows,
                  maxExcelRows,
                })
              : t("REPORTS_EXPORT_EXCEL")
          }
          placement={"top"}
        >
          <span>
            <IconButton
              aria-label="download"
              onClick={() => {
                if (numSelected > 0) {
                  handleDownloadExcel(TYPE_SELECTED);
                } else {
                  handleDownloadExcel(TYPE_ALL);
                }
              }}
              className={classes.downloadExcelBtn}
              disabled={exporting || isExportDisabledByTotalRows}
            >
              {exporting ? <CircularProgress size={14} /> : <GetAppIcon />}
            </IconButton>
          </span>
        </Tooltip>
      </>
    </Toolbar>
  );
};

export default TableToolbar;
