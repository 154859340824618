import React, { useState, useEffect, useMemo } from "react";

import {
  Icon,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import {
  find,
  isArray,
  isEmpty,
  isNil,
  isObjectLike,
  size,
  some,
  trim,
} from "lodash";
import {
  SEARCH_CRITERIA_EQUAL,
  SEARCH_CRITERIA_IN,
  SEARCH_CRITERIA_LIKE,
} from "../../../../util/Constants";

import { commonIcon, useStyles } from "./FilterFieldStyles";
import { useTranslation } from "react-i18next";
import { GENERIC_ALL } from "../../../../util/gen-dashboard-constants";

export function isArrayOfArrays(arr) {
  if (arr.every((elem) => typeof elem === "string")) {
    return false;
  } else if (arr.every((elem) => Array.isArray(elem))) {
    return true;
  } else {
    return false;
  }
}

export function giveMeCorrectValue({ filterComboOptions, defaultValue }) {
  if (
    isArrayOfArrays(filterComboOptions) &&
    some(filterComboOptions, function (o) {
      return (
        !isNil(o) && !isEmpty(o) && isArray(o) && trim(o[0]) === defaultValue
      );
    })
  ) {
    const item = find(filterComboOptions, function (o) {
      return (
        !isNil(o) && !isEmpty(o) && isArray(o) && trim(o[0]) === defaultValue
      );
    });
    if (!isNil(item) && !isEmpty(item) && isArray(item) && item.length >= 2) {
      return trim(item[0]);
    }
  } else {
    return trim(defaultValue);
  }
}

export function giveMeLabelOfOperation(operation) {
  if (operation === SEARCH_CRITERIA_LIKE) {
    return "SEARCH_CRITERIA_LIKE";
  } else {
    return "SEARCH_CRITERIA_EQUAL";
  }
}

export function giveMeLabelOfValue({ filterComboOptions, defaultValue }) {
  if (
    isArrayOfArrays(filterComboOptions) &&
    some(filterComboOptions, function (o) {
      return (
        !isNil(o) && !isEmpty(o) && isArray(o) && trim(o[0]) === defaultValue
      );
    })
  ) {
    const item = find(filterComboOptions, function (o) {
      return (
        !isNil(o) && !isEmpty(o) && isArray(o) && trim(o[0]) === defaultValue
      );
    });
    if (!isNil(item) && !isEmpty(item) && isArray(item) && item.length >= 2) {
      return trim(item[1]);
    }
  } else {
    return trim(defaultValue);
  }
}

export const PaginatedMenu = ({
  field,
  index,
  comboOptions,
  defaultOperation,
  handleFilterDialog,
  itemsPerPage,
  handleClose,
  anchorEl,
  minOptionsToPaginate,
  isComboWithMultipleOutput,
  multipleValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const startIndex = page * itemsPerPage;
  const endIndex = (page + 1) * itemsPerPage;
  const paginatedOptions = comboOptions.slice(startIndex, endIndex);

  if (comboOptions.length <= minOptionsToPaginate) {
    return (
      <Menu
        id={`${field}-${index}-combo`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.paperMenu }}
      >
        {isComboWithMultipleOutput === true && (
          <MenuItem
            key={GENERIC_ALL}
            onClick={() =>
              handleFilterDialog(GENERIC_ALL, false, SEARCH_CRITERIA_IN)
            }
            className={classes.listItemMultiple}
          >
            <Checkbox
              checked={
                !isNil(multipleValue) &&
                !isEmpty(multipleValue) &&
                size(comboOptions) === size(multipleValue)
              }
            />
            <ListItemText primary={t("GEN_DASH_FILTER_ALL")} />
          </MenuItem>
        )}
        {comboOptions.map((option, i) => {
          if (isComboWithMultipleOutput === true) {
            return (
              <MenuItem
                key={i}
                onClick={() =>
                  handleFilterDialog(option, false, SEARCH_CRITERIA_IN)
                }
                className={classes.listItemMultiple}
              >
                <Checkbox
                  checked={
                    !isNil(multipleValue) &&
                    !isEmpty(multipleValue) &&
                    multipleValue.indexOf(option) > -1
                  }
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  const operation = defaultOperation || SEARCH_CRITERIA_EQUAL;
                  handleFilterDialog(option, false, operation);
                }}
              >
                {option.label}
              </MenuItem>
            );
          }
        })}
      </Menu>
    );
  }

  return (
    <Menu
      id={`${field}-${index}-combo`}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{ paper: classes.paperMenu }}
    >
      {isComboWithMultipleOutput === true && (
        <MenuItem
          key={GENERIC_ALL}
          onClick={() =>
            handleFilterDialog(GENERIC_ALL, false, SEARCH_CRITERIA_IN)
          }
          className={classes.listItemMultiple}
        >
          <Checkbox
            checked={
              !isNil(multipleValue) &&
              !isEmpty(multipleValue) &&
              size(comboOptions) === size(multipleValue)
            }
          />
          <ListItemText primary={t("GEN_DASH_FILTER_ALL")} />
        </MenuItem>
      )}
      {paginatedOptions.map((option, i) => {
        if (isComboWithMultipleOutput === true) {
          return (
            <MenuItem
              key={startIndex + i}
              onClick={() =>
                handleFilterDialog(option, false, SEARCH_CRITERIA_IN)
              }
              className={classes.listItemMultiple}
            >
              <Checkbox
                checked={
                  !isNil(multipleValue) &&
                  !isEmpty(multipleValue) &&
                  multipleValue.indexOf(option) > -1
                }
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        } else {
          return (
            <MenuItem
              key={startIndex + i}
              onClick={() => {
                const operation = defaultOperation || SEARCH_CRITERIA_EQUAL;
                handleFilterDialog(option, false, operation);
              }}
            >
              {option.label}
            </MenuItem>
          );
        }
      })}
      <MenuItem style={{ display: "flex" }}>
        {comboOptions.length > itemsPerPage && (
          <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
            <Icon
              className="fas fa-arrow-left"
              style={{ ...commonIcon, marginRight: 5 }}
            />
            {t("PAGINATION_PREV_PAGE")}
          </Button>
        )}
        {comboOptions.length > itemsPerPage && (
          <Button
            onClick={() => setPage(page + 1)}
            disabled={endIndex >= comboOptions.length}
          >
            <Icon
              className="fas fa-arrow-right"
              style={{ ...commonIcon, marginRight: 5 }}
            />
            {t("PAGINATION_NEXT_PAGE")}
          </Button>
        )}
      </MenuItem>
    </Menu>
  );
};

export default function FilterFieldCombo({
  field,
  type,
  title,
  index,
  handleChangeFilterValue,
  comboOptions: filterComboOptions,
  owner,
  defaultValue,
  defaultOperation,
  isLimit,
  canRemove,
  blockInteraction,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [operation, setOperation] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const filterKey = `${field}-${index}`;

  function handleOpen(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleFilterDialog(filterValue, cancel, operation) {
    if (!cancel) {
      setValue(filterValue);
      setOperation(operation);
      handleChangeFilterValue({
        value: filterValue?.value,
        field,
        type,
        key: filterKey,
        operation,
        owner,
        isLimit,
      });
    }
    setAnchorEl(null);
  }

  useEffect(() => {
    const correctValueToSet = giveMeCorrectValue({
      defaultValue,
      filterComboOptions,
    });
    setValue(correctValueToSet);
    setOperation(defaultOperation);
  }, [defaultValue, defaultOperation, filterComboOptions]);

  const comboOptions = useMemo(() => {
    if (!isNil(filterComboOptions) && !isEmpty(filterComboOptions)) {
      const hasArraysInside = isArrayOfArrays(filterComboOptions);
      if (hasArraysInside) {
        return filterComboOptions.map((x) => {
          if (!isNil(x) && !isEmpty(x) && isArray(x) && x.length >= 2) {
            return {
              value: trim(x[0]),
              label: trim(x[1]),
            };
          } else {
            return {
              value: trim(x),
              label: trim(x),
            };
          }
        });
      } else {
        return filterComboOptions.map((x) => {
          return {
            value: x,
            label: x,
          };
        });
      }
    } else {
      return [];
    }
  }, [filterComboOptions]);

  const opAndVal =
    operation && value
      ? `${
          operation === SEARCH_CRITERIA_EQUAL
            ? operation
            : t(giveMeLabelOfOperation(operation))
        } ${giveMeLabelOfValue({
          defaultValue: isObjectLike(value) ? value?.label : value,
          filterComboOptions,
        })}`
      : "";

  const cantItems =
    !isNil(comboOptions) && !isEmpty(comboOptions)
      ? ` (${comboOptions.length})`
      : "";

  const isFilterApplied =
    (!isNil(value) && !isEmpty(value)) ||
    (!isNil(value?.value) && !isEmpty(value?.value));

  const hasToShowTooltipNoInfo = isNil(comboOptions) || isEmpty(comboOptions);

  return (
    <>
      <Tooltip
        title={t("QUERY_FILTERS_COMBO_NO_OPTIONS")}
        disableHoverListener={!hasToShowTooltipNoInfo}
      >
        <span>
          <button
            key={filterKey}
            className={
              hasToShowTooltipNoInfo
                ? classes.filterButtonDisabled
                : value
                ? classes.filterButtonWithValue
                : classes.filterButton
            }
            onClick={handleOpen}
            aria-controls={`${field}-${index}-combo`}
            disabled={hasToShowTooltipNoInfo || blockInteraction === true}
          >
            {isFilterApplied && (
              <Icon
                className="fas fa-filter"
                style={{ ...commonIcon, fontSize: "12px" }}
              />
            )}
            <Typography>
              {title} {opAndVal} {cantItems}
            </Typography>
            {isFilterApplied &&
              canRemove !== false &&
              blockInteraction !== true && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterDialog(null, false, null);
                  }}
                  className={classes.removeFilterButton}
                >
                  <Icon className="fas fa-times" style={commonIcon} />
                </IconButton>
              )}
          </button>
        </span>
      </Tooltip>

      <PaginatedMenu
        anchorEl={anchorEl}
        field={field}
        index={index}
        comboOptions={comboOptions}
        defaultOperation={defaultOperation}
        handleFilterDialog={handleFilterDialog}
        itemsPerPage={20}
        handleClose={() => handleFilterDialog(null, true, null)}
        minOptionsToPaginate={20}
      />
    </>
  );
}
