import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { Divider, Icon } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import { head, isArray, isEmpty, isNil, last, sortBy, toLower } from "lodash";

const CommonIcon = withStyles(() => ({
  root: {
    fontSize: 12,
  },
}))(Icon);

const useStyles = makeStyles(() => ({
  root: {
    "& .appear-item": {
      visibility: "hidden",
    },
    "&:hover .appear-item": {
      visibility: "visible",
    },
  },
}));

// function that recive the column and the mask of string to convert
function convertMask(mask, value) {
  try {
    const position = mask.toString().split(".").length;
    return value.toFixed(position);
  } catch (error) {
    return value;
  }
}

function HeaderMenu({
  title,
  tableProps,
  updateColumn,
  handleToggleGroupBy,
  disableShowMenu,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { column, columns } = tableProps;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGroupBy = () => {
    handleToggleGroupBy(column);
    setAnchorEl(null);
  };

  const handleSortBy = (function () {
    return {
      asc: function () {
        column.toggleSortBy(false, false);
        setAnchorEl(null);
      },
      desc: function () {
        column.toggleSortBy(true, false);
        setAnchorEl(null);
      },
    };
  })();

  const maxValue = (leafValues) => {
    return isNaN(Math.max(...leafValues)) ? 0 : Math.max(...leafValues);
  };

  const maxValueSt = (leafValues) => {
    return !isNil(leafValues) && !isEmpty(leafValues) && isArray(leafValues)
      ? last(sortBy(leafValues))
      : "";
  };

  const minValue = (leafValues) => {
    return isNaN(Math.min(...leafValues)) ? 0 : Math.min(...leafValues);
  };

  const minValueSt = (leafValues) => {
    return !isNil(leafValues) && !isEmpty(leafValues) && isArray(leafValues)
      ? head(sortBy(leafValues))
      : "";
  };

  const handleUpdateColumn = () =>
    updateColumn(columns.map((c) => (c.id === column.id ? column : c)));

  const handleAggregated = (function () {
    return {
      sum: function () {
        column.aggregate = "sum";
        column.Aggregated = ({ value }) =>
          column.mask.length ? convertMask(column.mask || "", value) : value;
        handleUpdateColumn();
        setAnchorEl(null);
      },
      avg: function () {
        column.aggregate = "average";
        column.Aggregated = ({ value }) =>
          column.mask.length ? convertMask(column.mask || "", value) : value;
        handleUpdateColumn();
        setAnchorEl(null);
      },
      count: function () {
        column.aggregate = "uniqueCount";
        column.Aggregated = ({ value }) => value;
        handleUpdateColumn();
        setAnchorEl(null);
      },
      max: function () {
        column.aggregate =
          toLower(column.type) === "string" ? maxValueSt : maxValue;
        column.Aggregated = ({ value }) =>
          column.mask.length ? convertMask(column.mask || "", value) : value;
        handleUpdateColumn();
        setAnchorEl(null);
      },
      min: function () {
        column.aggregate =
          toLower(column.type) === "string" ? minValueSt : minValue;
        column.Aggregated = ({ value }) =>
          column.mask.length ? convertMask(column.mask || "", value) : value;
        handleUpdateColumn();
        setAnchorEl(null);
      },
      blank: function () {
        column.aggregate = null;
        column.Aggregated = null;
        handleUpdateColumn();
        setAnchorEl(null);
      },
    };
  })();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className={classes.root}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span {...column.getSortByToggleProps()} title="">
          {title}
          {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
          {column.aggregate ? (
            <CommonIcon
              style={{ marginLeft: 5, paddingTop: 1 }}
              className={`${
                column.aggregate?.name === "maxValue"
                  ? "fas fa-angle-double-up"
                  : column.aggregate?.name === "minValue"
                  ? "fas fa-angle-double-down"
                  : column.aggregate === "sum"
                  ? "fas fa-plus"
                  : column.aggregate === "average"
                  ? "fas fa-sliders-h"
                  : column.aggregate === "uniqueCount"
                  ? "fas fa-hashtag"
                  : "blank"
              }`}
            />
          ) : (
            ""
          )}
        </span>

        {column.isGrouped ? (
          <Icon
            className="far fa-object-group"
            style={{ fontSize: 12, marginLeft: 5, cursor: "pointer" }}
            onClick={() => handleToggleGroupBy(column)}
          />
        ) : null}
      </div>

      {disableShowMenu !== true && (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            padding: 0,
          }}
          className="appear-item"
        >
          <MenuIcon style={{ color: "white" }} />
        </IconButton>
      )}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "30ch",
          },
        }}
      >
        <MenuItem
          key="order-asc"
          onClick={handleSortBy.asc}
          disabled={column.isSorted && !column.isSortedDesc}
        >
          <ListItemText primary={t("REPORTS_MENU_SORT_ASC")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-sort-alpha-down" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="order-desc"
          onClick={handleSortBy.desc}
          disabled={column.isSorted && column.isSortedDesc}
        >
          <ListItemText primary={t("REPORTS_MENU_SORT_DESC")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-sort-alpha-down-alt" />
          </ListItemIcon>
        </MenuItem>
        <Divider light />
        <MenuItem
          key="agrupar"
          onClick={handleGroupBy}
          disabled={column.isGrouped}
        >
          <ListItemText primary={t("REPORTS_MENU_GROUP")} />
          <ListItemIcon>
            <CommonIcon className="far fa-object-group" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="desagrupar"
          onClick={handleGroupBy}
          disabled={!column.isGrouped}
        >
          <ListItemText primary={t("REPORTS_MENU_UNGROUP")} />
          <ListItemIcon>
            <CommonIcon className="far fa-object-ungroup" />
          </ListItemIcon>
        </MenuItem>
        <Divider light />
        <MenuItem
          key="aggr-sum"
          onClick={handleAggregated.sum}
          selected={column.aggregate === "sum"}
        >
          <ListItemText primary={t("REPORTS_MENU_ADD")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-plus" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="aggr-count"
          onClick={handleAggregated.count}
          selected={column.aggregate === "count"}
        >
          <ListItemText primary={t("REPORTS_MENU_COUNT")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-hashtag" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="aggr-avg"
          onClick={handleAggregated.avg}
          selected={column.aggregate === "average"}
        >
          <ListItemText primary={t("REPORTS_MENU_AVG")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-sliders-h" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="aggr-max"
          onClick={handleAggregated.max}
          selected={column.aggregate?.name === "maxValue"}
        >
          <ListItemText primary={t("REPORTS_MENU_MAX")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-angle-double-up" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="aggr-min"
          onClick={handleAggregated.min}
          selected={column.aggregate?.name === "minValue"}
        >
          <ListItemText primary={t("REPORTS_MENU_MIN")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-angle-double-down" />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          key="aggr-blank"
          onClick={handleAggregated.blank}
          selected={column.aggregate === null}
        >
          <ListItemText primary={t("REPORTS_MENU_NONE")} />
          <ListItemIcon>
            <CommonIcon className="fas fa-ban" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default React.memo(HeaderMenu);
