import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useGlobalFilter,
  useRowSelect,
  useColumnOrder,
} from "react-table";
import _, { has, isArray, isEmpty, isNil, isString, size, split } from "lodash";
import { useTranslation } from "react-i18next";
import { matchSorter } from "match-sorter";
import MUITable from "@material-ui/core/Table";
import MUITableBody from "@material-ui/core/TableBody";
import MUITableCell from "@material-ui/core/TableCell";
import MUITableHead from "@material-ui/core/TableHead";
import MUITableRow from "@material-ui/core/TableRow";
import MUITablePagination from "@material-ui/core/TablePagination";
import MUITableContainer from "@material-ui/core/TableContainer";
import {
  Checkbox,
  Divider,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import * as API from "../api/api";
import { useStyles } from "./styles";
import TablePaginationActions from "../components/TablePaginationActions";
import TableToolbar from "../components/TableToolbar";
import { handlerColumnFilter } from "../components/FilterMenu";
import FileCellRender from "../components/FileCellRender";
import HeaderMenu from "../components/HeaderMenu";
import {
  HTTP_STATUS_UNAUTHORIZED,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import CustomBodyToast from "../../toast/CustomBodyToast";
import { toast } from "react-toastify";
import { AuthContext } from "../../../core/providers/AuthContext";
import ConfirmQueryActionDialog from "../components/ConfirmQueryActionDialog";
import QueryActionLoaderModal from "../components/QueryActionLoaderModal";
import {
  getKeyForMaps,
  useExternalReportsStore,
} from "../../../core/stores/ExternalReportsStore";

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100, 250, 500];
const PAGE_SIZE_INITIAL = 25;

function mapValuesOpenFormDefinition(definition, values) {
  return Object.keys(definition).reduce((result, key) => {
    const mappedKey = definition[key];
    const value = values[key];
    result[mappedKey] = value;
    return result;
  }, {});
}

function resolveIfQueryActionBtnIsDisabled({
  rowValuesToUse,
  btnDisabled,
  selectedFlatRows,
  rowId,
  multipleExecutionEnabled,
}) {
  let result = false;

  if (
    !_.isNil(selectedFlatRows) &&
    _.isArray(selectedFlatRows) &&
    !_.isEmpty(selectedFlatRows) &&
    !_.isNil(rowId)
  ) {
    result =
      multipleExecutionEnabled === true
        ? _.some(selectedFlatRows, { id: rowId }) === false
        : true;
  }

  if (
    result === false &&
    !_.isNil(rowValuesToUse) &&
    !_.isEmpty(rowValuesToUse) &&
    !_.isNil(btnDisabled) &&
    _.isObject(btnDisabled) &&
    !_.isEmpty(btnDisabled)
  ) {
    for (const field in btnDisabled) {
      const valueToDisable = _.get(btnDisabled, field);
      if (
        _.has(rowValuesToUse, field) &&
        _.get(rowValuesToUse, field) === valueToDisable
      ) {
        result = true;
      } else {
        result = false;
        break;
      }
    }
  }
  return result;
}

function validateAllRowsHasQA({ selectedFlatRows, btnDisabled }) {
  let result = true;
  if (
    !isNil(selectedFlatRows) &&
    isArray(selectedFlatRows) &&
    !isEmpty(selectedFlatRows)
  ) {
    for (const sfr of selectedFlatRows) {
      if (!isNil(sfr)) {
        const { original, id } = sfr;
        const isDisabledThisAction = resolveIfQueryActionBtnIsDisabled({
          rowValuesToUse: original,
          selectedFlatRows,
          btnDisabled,
          multipleExecutionEnabled: true,
          rowId: id,
        });

        if (isDisabledThisAction === true) {
          result = false;
          break;
        }
      }
    }
  }

  return result;
}

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          ref={resolvedRef}
          {...rest}
          className="checkbox"
          size="small"
        />
      </>
    );
  }
);

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;
function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = new Date(filterValues[0]);
  let ed = new Date(filterValues[1]);
  return rows.filter((r) => {
    var time = new Date(r.values[id]);
    if (filterValues.length === 0) return rows;
    return time >= sd && time <= ed;
  });
}

dateBetweenFilterFn.autoRemove = (val) => !val;

function multipleSelectFilterFn(rows, id, filterValue) {
  if (
    !_.isNil(filterValue) &&
    !_.isEmpty(filterValue) &&
    _.isArray(filterValue)
  ) {
    let result = [];
    for (var i = 0, n = filterValue.length; i < n; ++i) {
      const matched = matchSorter(rows, filterValue[i], {
        keys: [(row) => row.values[id]],
      });
      if (!_.isNil(matched) && !_.isEmpty(matched) && _.isArray(matched)) {
        result.push(...matched);
      }
    }
    return result || [];
  } else {
    return rows;
  }
}

multipleSelectFilterFn.autoRemove = (val) => !val;

export const INITIAL_DIALOG_CONFIRM_QUERY_ACTION = {
  open: false,
  title: null,
  params: null,
};

export default function ExternalTable({
  columns,
  data,
  title,
  hiddenColumns: propsHiddenColumns,
  handleOpenModalForm,
  path,
  idForm,
  keyTable,
  keyReport,
  queryFilters,
  selectedTab,
  hasToBlockIndividualFiltersByLimit,
  isMaterializedQuery,
  handleFilterMaterializedQuery,
  countRows,
  overrideMaxExcelRows,
  lastSearch,
  handleRefreshCurrent,
  lastModDateMaterialized,
  historyBtnFrontConfig,
  handleOpenProcessHistoryDialog,
  actions,
  globalActions,
  coordinationFilters,
  disableSelectAll,
  disableSelectRow,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);
  const [confirmQueryActionDialog, setConfirmQueryActionDialog] = useState(
    INITIAL_DIALOG_CONFIRM_QUERY_ACTION
  );
  const [openQueryActionLoaderModal, setOpenQueryActionLoaderModal] =
    useState(false);

  const hiddenColumns = useMemo(() => {
    return propsHiddenColumns;
  }, [propsHiddenColumns]);

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      includesMultiple: multipleSelectFilterFn,
    }),
    []
  );

  const handleOpenModal = ({
    ukey,
    idFormRow,
    pathRow,
    defaultOperationRow,
    overrideOpsRow,
  }) => {
    handleOpenModalForm({
      open: true,
      ukey: ukey,
      idFormRow,
      pathRow,
      defaultOperationRow,
      overrideOpsRow,
    });
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: handlerColumnFilter,
      FileCell: (params) => {
        return <FileCellRender {...params} />;
      },
      Aggregated: (params) => {
        if (!_.isNil(params) && !_.isNil(params.value)) {
          if (_.isNumber(params.value)) {
            return _.round(params.value, 2);
          } else {
            return params?.value;
          }
        } else {
          return params?.value;
        }
      },
    }),
    []
  );

  const sGroupBy = useExternalReportsStore((state) => state.sGroupBy);

  const sPageSize = useExternalReportsStore((state) => state.sPageSize);
  const modifyPageSize = useExternalReportsStore(
    (state) => state.modifyPageSize
  );

  const selectedViewReport = useExternalReportsStore(
    (state) => state.selectedViewReport
  );
  const selectedView = selectedViewReport.get(keyReport) || null;

  const keyToAsk = getKeyForMaps({ keyTable, selectedView });

  async function executeQueryAction({
    keyAction,
    line,
    row,
    needRefreshAfterExecute,
    cols,
    openFormDef,
    hideQueryActionLoaderModal,
    openQueryDef,
    confirmFields,
  }) {
    if (hideQueryActionLoaderModal !== true && isNil(openQueryDef)) {
      setOpenQueryActionLoaderModal(true);
    }

    let toastContent = null;
    let wasOk = false;
    let response = null;
    if (!isNil(openFormDef) && !isEmpty(openFormDef)) {
      const { path, idForm, useUkey, params, formOperations } = openFormDef;
      if (useUkey === false) {
        //open form with set values
        const valuesToSet = mapValuesOpenFormDefinition(params, row);
        handleOpenModalForm({
          open: true,
          idFormRow: idForm,
          pathRow: path,
          overrideOpsRow: formOperations || null,
          useUkey: false,
          values: valuesToSet,
        });
      } else {
        //open form with ukey
        if (isNil(row?.ukey) || !has(row, "ukey")) {
          toastContent = toastContent = (
            <CustomBodyToast title={t("QUERY_OPEN_FORM_NOT_UKEY")} />
          );
        } else {
          handleOpenModal({
            ...row,
            pathRow: row.original?.path || path,
            idFormRow: row.original?.idForm || idForm,
            overrideOpsRow: formOperations || null,
          });
        }
      }
    } else if (!isNil(openQueryDef) && !isEmpty(openQueryDef)) {
      toast.warn("Nested external queries are not allowed", {
        containerId: TOAST_CONTAINER_LAYOUT,
      });
      return;
    } else {
      const body = {
        query: keyReport,
        selectedSection: selectedTab,
        line,
        row,
        cols,
        confirmFields,
      };
      response = await API.executeQueryAction({
        REQUEST_HEADERS,
        body,
        keyAction,
      });

      if (!_.isNil(response)) {
        const { status } = response;
        if (
          status === HTTP_STATUS_UNAUTHORIZED &&
          !_.isNil(logout) &&
          _.isFunction(logout)
        ) {
          logout(true);
        } else {
          const { ok, msg } = response;
          if (ok === true) {
            wasOk = true;
            if (hideQueryActionLoaderModal !== true) {
              toast.success(t("FORM_SUCCESS_ACTION"), {
                containerId: TOAST_CONTAINER_LAYOUT,
                toastId: "executeQueryAction",
              });
            }
          } else {
            const rightMsg =
              !_.isNil(msg) && !_.isEmpty(msg)
                ? msg
                : t("ERROR_RESOURCE_NOT_FOUND_TEXT");
            toastContent = <CustomBodyToast title={rightMsg} />;
          }
        }
      } else {
        toastContent = (
          <CustomBodyToast title={t("ERROR_RESOURCE_NOT_FOUND_TEXT")} />
        );
      }
    }

    if (toastContent !== null && hideQueryActionLoaderModal !== true) {
      toast.error(toastContent, {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "executeQueryAction",
      });
    }

    if (hideQueryActionLoaderModal !== true) {
      setTimeout(() => {
        setOpenQueryActionLoaderModal(false);
      }, 300);

      if (needRefreshAfterExecute && wasOk) {
        handleRefreshCurrent();
      }
    }

    return response;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    selectedFlatRows,
    getToggleAllRowsExpandedProps,
    isAllRowsExpanded,
    setGroupBy,
    setHiddenColumns,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, groupBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
      initialState: {
        hiddenColumns,
        pageSize: PAGE_SIZE_INITIAL,
      },
    },
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const hasToShowHistoryColumn =
          !_.isNil(historyBtnFrontConfig) &&
          _.isArray(historyBtnFrontConfig) &&
          !_.isEmpty(historyBtnFrontConfig);

        const hasToShowActionsColumns =
          !_.isNil(actions) && _.isArray(actions) && !_.isEmpty(actions);

        const historyColumn = hasToShowHistoryColumn
          ? [
              {
                id: "history-form",
                groupByBoundary: true,
                width: 35,
                title: t("GEN_DASH_HISTORY"),
                Cell: ({ row }) => {
                  return (
                    <Tooltip title={t("GEN_DASH_HISTORY")}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleOpenProcessHistoryDialog({
                            config: historyBtnFrontConfig,
                            rowData: row?.values || rows?.original || null,
                          });
                        }}
                      >
                        <Icon
                          className={[
                            classes.iconExternal,
                            "fas fa-history",
                          ].join(" ")}
                        />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            ]
          : [];

        const actionColumns = hasToShowActionsColumns
          ? actions.map((actionObject) => {
              const {
                btnColor,
                btnTooltip,
                btnIcon,
                btnIconSize,
                colName,
                colWidth,
                keyAction,
                needRefreshAfterExecute,
                btnDisabled,
                needConfirmDialog,
                confirmDialogTitle,
                openFormDef,
                multipleExecutionEnabled,
                multipleConfirmDialogTitle,
                openQueryDef,
                endpointGetConfirmationFields,
              } = actionObject;

              return {
                id: keyAction,
                groupByBoundary: true,
                title: colName,
                align: "center",
                sortable: false,
                filterable: false,
                width: !_.isNil(colWidth) && colWidth,
                Header: (props) => (
                  <HeaderMenu
                    title={colName}
                    tableProps={props}
                    disableShowMenu={true}
                  />
                ),
                Cell: (cellProps) => {
                  const { allColumns, row, selectedFlatRows } = cellProps;
                  const rowValuesToUse = row?.original || row?.values;
                  const isBtnDisabled = resolveIfQueryActionBtnIsDisabled({
                    rowValuesToUse,
                    btnDisabled,
                    selectedFlatRows,
                    rowId: row?.id,
                    multipleExecutionEnabled,
                  });

                  const multipleExecution =
                    !_.isNil(selectedFlatRows) &&
                    _.isArray(selectedFlatRows) &&
                    _.size(selectedFlatRows) > 1
                      ? true
                      : false;

                  const params = {
                    keyAction,
                    line: _.toSafeInteger(row?.index),
                    row: rowValuesToUse,
                    cols: _.filter(allColumns, function (c) {
                      return !_.isNil(c?.keyTable);
                    }).map((c) => {
                      return {
                        title: c?.title,
                        field: c?.field,
                        mask: c?.mask,
                        type: c?.type,
                        isFile: c?.isFile,
                        hidden: c?.hidden,
                        description: c?.description,
                        align: c?.align,
                        width: c?.width,
                      };
                    }),
                    needRefreshAfterExecute,
                    openFormDef,
                    multipleExecution,
                    selectedFlatRows,
                    openQueryDef,
                    endpointGetConfirmationFields,
                  };

                  return (
                    <Tooltip
                      title={btnTooltip}
                      disableHoverListener={
                        isBtnDisabled ||
                        isNil(btnTooltip) ||
                        isEmpty(btnTooltip)
                      }
                      style={{
                        cursor: isBtnDisabled ? "not-allowed" : "pointer",
                      }}
                    >
                      <span>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (
                              (needConfirmDialog === true &&
                                !_.isNil(confirmDialogTitle) &&
                                _.isString(confirmDialogTitle) &&
                                !_.isEmpty(confirmDialogTitle)) ||
                              params?.multipleExecution === true
                            ) {
                              const validationRowsQA =
                                params?.multipleExecution === true &&
                                size(selectedFlatRows) > 0
                                  ? validateAllRowsHasQA({
                                      keyAction,
                                      selectedFlatRows,
                                      btnDisabled,
                                    })
                                  : true;
                              if (validationRowsQA) {
                                setConfirmQueryActionDialog({
                                  open: true,
                                  title:
                                    params?.multipleExecution === true
                                      ? multipleConfirmDialogTitle ||
                                        confirmDialogTitle
                                      : confirmDialogTitle,
                                  params,
                                });
                              } else {
                                toast.warn(
                                  t(
                                    "REPORTS_QUERY_ACTION_MULTIPLE_PRE_VALIDATION",
                                    { colName }
                                  ),
                                  {
                                    containerId: TOAST_CONTAINER_LAYOUT,
                                    autoClose: 8000,
                                  }
                                );
                              }
                            } else {
                              executeQueryAction(params);
                            }
                          }}
                          disabled={isBtnDisabled}
                        >
                          <Icon
                            className={[classes.iconExternal, btnIcon].join(
                              " "
                            )}
                            style={{
                              color: isBtnDisabled
                                ? "grey"
                                : !_.isNil(btnColor) && !_.isEmpty(btnColor)
                                ? _.isObject(btnColor)
                                  ? btnColor?.[theme.palette.type]
                                  : btnColor
                                : null,
                              fontSize: !_.isNil(btnIconSize) && btnIconSize,
                            }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  );
                },
              };
            })
          : [];

        const selectionColumn =
          disableSelectAll === true && disableSelectRow === true
            ? []
            : [
                {
                  id: "selection",
                  groupByBoundary: true,
                  width: 35,
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                      {disableSelectAll === true ? null : (
                        <IndeterminateCheckbox
                          {...getToggleAllRowsSelectedProps()}
                        />
                      )}
                    </div>
                  ),
                  Cell: ({ row }) => (
                    <div>
                      {disableSelectRow === true ? null : (
                        <IndeterminateCheckbox
                          {...row.getToggleRowSelectedProps()}
                        />
                      )}
                    </div>
                  ),
                },
              ];

        return [
          ...selectionColumn,
          {
            id: "selection-form",
            groupByBoundary: true,
            width: 35,
            title: t("FORM_OPEN"),
            Cell: ({ row }) => {
              if (
                ((!_.isNil(row.original?.path) &&
                  !_.isNil(row.original?.idForm)) ||
                  (!_.isNil(path) && !_.isNil(idForm))) &&
                !_.isNil(row.values?.ukey)
              ) {
                return (
                  <Tooltip title={t("FORM_OPEN")}>
                    <IconButton
                      onClick={() =>
                        handleOpenModal({
                          ...row.values,
                          pathRow: row.original?.path || path,
                          idFormRow: row.original?.idForm || idForm,
                          defaultOperationRow:
                            row.original?.defaultOperation || null,
                          overrideOpsRow: !isNil(row.original?.overrideOps)
                            ? isString(row.original?.overrideOps)
                              ? split(row.original?.overrideOps, ",")
                              : row.original?.overrideOps
                            : null,
                        })
                      }
                    >
                      <Icon
                        className={[
                          classes.iconExternal,
                          "fas fa-external-link-alt",
                        ].join(" ")}
                      />
                    </IconButton>
                  </Tooltip>
                );
              } else {
                return null;
              }
            },
          },
          ...historyColumn,
          ...columns,
          ...actionColumns,
        ];
      });
    }
  );

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [setHiddenColumns, hiddenColumns]);

  useEffect(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    if (
      !_.isNil(sPageSize) &&
      !_.isEmpty(sPageSize) &&
      !_.isNil(sPageSize.get(keyToAsk))
    ) {
      const pageSizeToSet = sPageSize.get(keyToAsk);
      setPageSize(pageSizeToSet);
    } else {
      setPageSize(PAGE_SIZE_INITIAL);
    }
    // eslint-disable-next-line
  }, [keyTable, sPageSize]);

  /**
   * Recover group by from store
   */
  useEffect(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    if (
      !_.isNil(sGroupBy) &&
      !_.isEmpty(sGroupBy) &&
      !_.isNil(sGroupBy.get(keyToAsk))
    ) {
      const groupByToSet = sGroupBy.get(keyToAsk);
      setGroupBy(groupByToSet);
    } else {
      setGroupBy([]);
    }
    // eslint-disable-next-line
  }, [keyTable, sGroupBy]);

  /**
   * Syncro group by to store
   */
  useEffect(() => {
    const keyToAsk = getKeyForMaps({ keyTable, selectedView });
    if (
      !_.isNil(sGroupBy) &&
      !_.isEmpty(groupBy) &&
      groupBy !== sGroupBy.get(keyToAsk)
    ) {
      //modifyGroupBy({ key: keyToAsk, newGroupBy: groupBy });
    } else {
      setGroupBy(sGroupBy.get(keyToAsk) || groupBy);
    }

    return () => null;
    // eslint-disable-next-line
  }, [groupBy]);

  function handleFilterMaterializedQueryInTable({ fts }) {
    handleFilterMaterializedQuery({
      fts,
      allColumns,
    });
  }

  function handleCloseAndRefresh() {
    setConfirmQueryActionDialog(INITIAL_DIALOG_CONFIRM_QUERY_ACTION);
    handleRefreshCurrent();
  }

  return (
    <div className="tableWrap">
      <QueryActionLoaderModal open={openQueryActionLoaderModal} />
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        title={title}
        selectedFlatRows={selectedFlatRows}
        getToggleAllRowsExpandedProps={getToggleAllRowsExpandedProps}
        isAllRowsExpanded={isAllRowsExpanded}
        isTableGrouped={groupBy && groupBy.length > 0}
        allColumns={allColumns}
        keyReport={keyReport}
        queryFilters={queryFilters?.queryFilters}
        selectedTab={selectedTab}
        hasToBlockIndividualFiltersByLimit={hasToBlockIndividualFiltersByLimit}
        isMaterializedQuery={isMaterializedQuery}
        handleFilterMaterializedQuery={handleFilterMaterializedQueryInTable}
        countRows={countRows}
        overrideMaxExcelRows={overrideMaxExcelRows}
        lastSearch={lastSearch}
        handleRefreshCurrent={handleRefreshCurrent}
        lastModDateMaterialized={lastModDateMaterialized}
        keyToAsk={keyToAsk}
        hideToolbarOptions={true}
        globalActions={globalActions}
        coordinationFilters={coordinationFilters}
      />
      {confirmQueryActionDialog.open === true ? (
        <ConfirmQueryActionDialog
          confirmQueryActionDialog={confirmQueryActionDialog}
          executeQueryAction={executeQueryAction}
          setConfirmQueryActionDialog={setConfirmQueryActionDialog}
          closeAndRefresh={() => handleCloseAndRefresh()}
        />
      ) : null}

      <MUITableContainer
        className="tableContainer"
        key={`${keyTable}-container`}
      >
        <MUITable
          {...getTableProps()}
          className="table"
          key={`${keyTable}-mui-table`}
        >
          <MUITableHead>
            {headerGroups.map((headerGroup, index) => (
              <React.Fragment key={index}>
                <MUITableRow
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.id}
                >
                  {headerGroup.headers.map((column) => {
                    const tooltipText = column?.description || "";
                    return (
                      <Tooltip key={column.id} title={tooltipText}>
                        <MUITableCell
                          {...column.getHeaderProps()}
                          className={classes.headCell}
                        >
                          {column.render("Header", column)}
                        </MUITableCell>
                      </Tooltip>
                    );
                  })}
                </MUITableRow>

                {_.isNil(isMaterializedQuery) ||
                  (isMaterializedQuery === false && (
                    <MUITableRow
                      {...headerGroup.getHeaderGroupProps()}
                      key={`${headerGroup.id}-filter`}
                    >
                      {headerGroup.headers.map((column) => (
                        <MUITableCell
                          {...column.getHeaderProps()}
                          key={column.id}
                          className={classes.headFilterCell}
                        >
                          <div>
                            {column.filterable
                              ? column.render("Filter", {
                                  hasToBlockIndividualFiltersByLimit,
                                })
                              : null}
                          </div>
                        </MUITableCell>
                      ))}
                    </MUITableRow>
                  ))}
              </React.Fragment>
            ))}
          </MUITableHead>
          <MUITableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <MUITableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const isFile = cell?.column?.isFile === true;

                    return (
                      <MUITableCell
                        {...cell.getCellProps()}
                        style={{
                          background: cell.isGrouped
                            ? "#3F66A222"
                            : cell.isAggregated || cell.isPlaceholder
                            ? "#3F66A222"
                            : "inhereit",
                          textAlign: !_.isNil(cell?.column?.align)
                            ? cell.column.align
                            : cell?.column?.type === "numeric" ||
                              cell?.column?.type === "number"
                            ? "right"
                            : "left",
                          width:
                            !_.isNil(cell?.column?.width) &&
                            cell?.column?.width,
                        }}
                      >
                        {cell.isGrouped ? (
                          <div style={{ display: "flex" }}>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <Icon
                                  className="fas fa-angle-down"
                                  style={{ fontSize: 16 }}
                                />
                              ) : (
                                <Icon
                                  className="fas fa-angle-right"
                                  style={{ fontSize: 16 }}
                                />
                              )}
                            </span>
                            {cell.render("Cell", {
                              editable: false,
                              isGrouped: true,
                            })}
                            ({row.subRows.length})
                          </div>
                        ) : cell.isAggregated ? (
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : isFile ? (
                          cell.render("FileCell", {
                            editable: true,
                            isGrouped: false,
                          })
                        ) : (
                          cell.render("Cell", {
                            editable: true,
                            isGrouped: false,
                          })
                        )}
                      </MUITableCell>
                    );
                  })}
                </MUITableRow>
              );
            })}
          </MUITableBody>
        </MUITable>
      </MUITableContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!_.isNil(countRows) && _.isNumber(countRows) && countRows > 0 ? (
          <Typography>
            {t("QUERY_TOTAL_ROWS_TEXT", {
              current: rows?.length || 0,
              total: countRows,
            })}
          </Typography>
        ) : (
          <Divider />
        )}

        <MUITablePagination
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          labelRowsPerPage={t("PAGINATION_ROWS_PER_PAGE")}
          count={rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          component="div"
          onPageChange={(ev, newPage) => gotoPage(newPage)}
          onRowsPerPageChange={(ev) =>
            modifyPageSize({
              key: keyToAsk,
              newPageSize: Number(ev.target.value),
            })
          }
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </div>
  );
}
