import { isArray, isEmpty, isNil, map } from "lodash";
import * as _ from "lodash";

export function resolveCoordtinationFiltersForExternalQuery({
  row,
  coordinationFiltersDef = [],
}) {
  if (
    !isNil(row) &&
    !isNil(coordinationFiltersDef) &&
    isArray(coordinationFiltersDef) &&
    !isEmpty(coordinationFiltersDef)
  ) {
    const result = map(coordinationFiltersDef, ({ from, to }) => ({
      owner: to,
      value: row[from],
      field: from,
    }));
    return result;
  }

  return [];
}

export const QUERIES_STYLE_VERSION_OLD = 1;
export const QUERIES_STYLE_VERSION_NEW = 2;

export function getCorrectStyleVersionToUse({ stylesVersion }) {
  if (isNil(stylesVersion) || stylesVersion === 1) {
    return QUERIES_STYLE_VERSION_OLD;
  } else {
    return QUERIES_STYLE_VERSION_NEW;
  }
}

export function resolveIfQueryActionBtnIsDisabled({
  rowValuesToUse,
  btnDisabled,
  selectedFlatRows,
  rowId,
  multipleExecutionEnabled,
}) {
  let result = false;

  if (
    !_.isNil(selectedFlatRows) &&
    _.isArray(selectedFlatRows) &&
    !_.isEmpty(selectedFlatRows) &&
    !_.isNil(rowId)
  ) {
    result =
      multipleExecutionEnabled === true
        ? _.some(selectedFlatRows, { id: rowId }) === false
        : true;
  }

  if (
    result === false &&
    !_.isNil(rowValuesToUse) &&
    !_.isEmpty(rowValuesToUse) &&
    !_.isNil(btnDisabled) &&
    _.isObject(btnDisabled) &&
    !_.isEmpty(btnDisabled)
  ) {
    for (const field in btnDisabled) {
      const valueToDisable = _.get(btnDisabled, field);
      if (
        _.has(rowValuesToUse, field) &&
        _.get(rowValuesToUse, field) === valueToDisable
      ) {
        result = true;
      } else {
        result = false;
        break;
      }
    }
  }
  return result;
}
