import React, { useState, useContext, useEffect, useMemo } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import { v4 as uuid } from "uuid";

//Own imports
import * as Constants from "../../util/Constants";
import BadForm from "../badForm/BadForm";
import { useStyles } from "./ReportsContainerStyles";
import ExternalFormContainer from "../forms/externalFormContainer/ExternalFormContainer";
import { LayoutContext } from "../../core/providers/LayoutContext";
import { useToast } from "./components/Toast";
import { AuthContext } from "../../core/providers/AuthContext";
import { Paper } from "@material-ui/core";
import FilterFieldsContainer from "./fileds/FilterFieldsContainer";
import {
  getKeyForMaps,
  useExternalReportsStore,
} from "../../core/stores/ExternalReportsStore";
import { useProcessHistoryDialog } from "../../core/hooks/useProcessHistoryDialog";
import ExternalFormContainerValues from "../forms/externalFormContainer/ExternalFormContainerValues";
import {
  UpdateExternalForm,
  useExternalFormShema,
} from "./hooks/useExternalFormShema";
import {
  executeExternalQuery,
  useExternalResultQuery,
} from "./hooks/useExternalResultQuery";
import ExternalResultTable from "./externalTable/ExternalResultTable";

function equalFilters(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = _.sortBy(arr1, ["field"]);
  const sortedArr2 = _.sortBy(arr2, ["field"]);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (!_.isEqual(sortedArr1[i]?.field, sortedArr2[i]?.field)) {
      return false;
    }
  }

  return true;
}

export default function ExternalReportsContainer() {
  const { auth } = useContext(AuthContext);
  const theme = useTheme();
  const { showToast, ToastContainerImpl } = useToast();
  const { isDrawerOpen } = useContext(LayoutContext);
  const classes = useStyles({ isDrawerOpen });

  const [openModal, setOpenModal] = useState(false);
  const [ukey, setUkey] = useState(false);
  const [actualPath, setActualPath] = useState("");
  const [actualForm, setActualForm] = useState("");
  const [actualDefaultOperation, setActualDefaultOperation] = useState("");
  const [actualOverrideOps, setActualOverrideOps] = useState(null);

  const [openFormWithValues, setOpenFormWithValues] = useState(
    Constants.INITIAL_OPEN_EXTERNAL_FORM_STATE
  );

  const {
    changeReport,
    selectedReportTab,
    changeSelectedReportTab,
    selectedViewReport,
    modifyGlobalFilters,
    sGlobalFilters,
    setViewsByReport,
    openExternalQuery,
  } = useExternalReportsStore();

  const formName = openExternalQuery?.queryKey;

  const coordinationFilters = useMemo(() => {
    return openExternalQuery?.coordinationFilters || [];
  }, [openExternalQuery]);

  const [{ status, error, data }, formDispatch] = useExternalFormShema();

  const selectedTab = selectedReportTab.get(data?.key) || 0;

  const [queryFilters, setQueryFilters] = useState(null);

  // eslint-disable-next-line
  const [{ status: queryStatus }, queryDispatch] = useExternalResultQuery();

  const selectedView = selectedViewReport.get(formName) || null;

  const [ProcessHistoryDialog, handleOpenProcessHistoryDialog] =
    useProcessHistoryDialog();

  useEffect(() => {
    const fetchData = async () => {
      const response = await UpdateExternalForm(formDispatch, formName, auth);
      return response;
    };

    fetchData().then((r) => {
      queryDispatch({ type: "REFRESH" });
      changeReport({ report: formName });
      setViewsByReport({
        report: formName,
        views: r?.form?.viewDefinitions,
      });
    });

    return () => null;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      status &&
      status.toUpperCase() !== "IDLE" &&
      status.toUpperCase() !== "FETCHING"
    ) {
      const keyTable = `${data?.key}-${selectedTab}`;
      const keyToAsk = getKeyForMaps({ keyTable, selectedView });
      if (
        !_.isNil(sGlobalFilters) &&
        !_.isEmpty(sGlobalFilters) &&
        _.isMap(sGlobalFilters) &&
        sGlobalFilters.has(keyToAsk) &&
        (!_.isEmpty(sGlobalFilters.get(keyToAsk)?.queryFilters) ||
          !_.isEmpty(sGlobalFilters.get(keyToAsk))) &&
        formName === data?.key
      ) {
        //recover
        const filtersToRecover = sGlobalFilters.get(keyToAsk);
        setQueryFilters({
          idForm: formName,
          queryFilters: filtersToRecover,
        });
      } else {
        if (
          formName &&
          data &&
          data?.route &&
          formName ===
            data?.route.substring(data?.route.lastIndexOf("/") + 1) &&
          _.isNil(queryFilters)
        ) {
          if (data?.filters) {
            const filtersToSend = data?.filters.map((x) => {
              return {
                ...x,
                value: x?.defaultValue || null,
                operation: x?.defaultOperation || null,
              };
            });
            setQueryFilters({
              idForm: formName,
              queryFilters: filtersToSend || [],
            });
          } else {
            setQueryFilters({
              idForm: formName,
              queryFilters: [],
            });
          }
        } else {
          if (data?.key === formName) {
            const filtersToSend =
              !_.isNil(data) &&
              !_.isNil(data?.filters) &&
              !_.isEmpty(data?.filters) &&
              _.isArray(data?.filters)
                ? data?.filters.map((x) => {
                    return {
                      ...x,
                      value: x?.defaultValue || null,
                      operation: x?.defaultOperation || null,
                    };
                  })
                : [];
            setQueryFilters({
              idForm: formName,
              queryFilters: filtersToSend || [],
            });
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [formName, data, status, selectedView]);

  useEffect(() => {
    if (
      queryDispatch &&
      queryFilters &&
      formName &&
      queryFilters?.idForm === formName &&
      data &&
      status &&
      status.toUpperCase() !== "IDLE" &&
      status.toUpperCase() !== "FETCHING" &&
      queryStatus &&
      queryStatus.toUpperCase() !== "FETCHING"
    ) {
      if (_.isArray(data?.filters) && _.isArray(queryFilters.queryFilters)) {
        if (equalFilters(data?.filters, queryFilters.queryFilters)) {
          const queryFiltersToSend =
            !_.isNil(coordinationFilters) &&
            !_.isEmpty(coordinationFilters) &&
            !_.isNil(queryFilters) &&
            !_.isNil(queryFilters?.queryFilters) &&
            _.isArray(queryFilters?.queryFilters) &&
            !_.isEmpty(queryFilters?.queryFilters)
              ? {
                  idForm: queryFilters?.idForm,
                  queryFilters: _.map(queryFilters?.queryFilters, function (o) {
                    const qOwner = o?.owner;

                    if (
                      !_.isNil(o) &&
                      _.some(coordinationFilters, { owner: qOwner })
                    ) {
                      const coordFilterToSet = _.find(coordinationFilters, {
                        owner: qOwner,
                      });

                      if (!_.isNil(coordFilterToSet)) {
                        return {
                          ...o,
                          value: coordFilterToSet.value,
                          operation: Constants.SEARCH_CRITERIA_EQUAL,
                          defaultValue: coordFilterToSet.value,
                          defaultOperation: Constants.SEARCH_CRITERIA_EQUAL,
                          canRemove: false,
                          blockInteraction: true,
                        };
                      } else {
                        return o;
                      }
                    } else {
                      return o;
                    }
                  }),
                }
              : queryFilters;

          executeExternalQuery(queryDispatch, queryFiltersToSend, auth.token);
        }
      } else if (_.isNil(data?.filters)) {
        executeExternalQuery(
          queryDispatch,
          {
            idForm: formName,
            queryFilters: [],
          },
          auth.token
        );
      }
    }
    //eslint-disable-next-line
  }, [
    queryDispatch,
    queryFilters,
    auth.token,
    data,
    status,
    formName,
    coordinationFilters,
  ]);

  useEffect(() => {
    if (
      data &&
      data?.key &&
      !_.isNil(selectedTab) &&
      !_.isNil(queryFilters) &&
      data?.key === formName
    ) {
      const keyTable = `${data?.key}-${selectedTab}`;
      const keyToAsk = getKeyForMaps({ keyTable, selectedView });
      let newGlobalFilters = queryFilters;
      if (
        !_.isNil(sGlobalFilters) &&
        !_.isEmpty(sGlobalFilters) &&
        sGlobalFilters.has(keyToAsk) &&
        _.includes(keyToAsk, formName)
      ) {
        const actualStoredQF = sGlobalFilters.get(keyToAsk);
        if (!_.isNil(actualStoredQF) && _.isArray(actualStoredQF)) {
          newGlobalFilters = {
            idForm: sGlobalFilters.get(keyToAsk)?.idForm,
            queryFilters: actualStoredQF.map((x) => {
              const foundInQf = _.find(queryFilters?.queryFilters, {
                field: x?.field,
                owner: x?.owner,
              });
              if (!_.isNil(foundInQf)) {
                return {
                  ...x,
                  value: foundInQf?.value,
                  operation: foundInQf?.operation,
                  defaultValue: foundInQf?.value,
                  defaultOperation: foundInQf?.operation,
                };
              } else {
                return x;
              }
            }),
          };
          modifyGlobalFilters({
            key: keyToAsk,
            newGlobalFilters,
          });
        } else {
          newGlobalFilters =
            queryFilters?.idForm === formName
              ? queryFilters?.queryFilters
              : data?.filters?.queryFilters || [];
          modifyGlobalFilters({
            key: keyToAsk,
            newGlobalFilters,
          });
        }
      } else {
        modifyGlobalFilters({
          key: keyToAsk,
          newGlobalFilters:
            newGlobalFilters || data?.filters?.queryFilters || [],
        });
      }
    }
    //eslint-disable-next-line
  }, [queryFilters, data, selectedTab, selectedView]);

  //Sort sections by position
  const sortedSections = useMemo(
    () => data?.sections?.sort((a, b) => a.position - b.position),
    [data]
  );

  const availableFilters = useMemo(() => {
    if (
      !_.isNil(coordinationFilters) &&
      !_.isEmpty(coordinationFilters) &&
      !_.isNil(queryFilters) &&
      !_.isNil(queryFilters?.queryFilters) &&
      _.isArray(queryFilters?.queryFilters) &&
      !_.isEmpty(queryFilters?.queryFilters) &&
      !_.isNil(data) &&
      !_.isNil(data.filters)
    ) {
      const initialFilters = data?.filters.map((x) => {
        const found = _.find(queryFilters, {
          owner: x?.owner,
          field: x?.field,
        });
        if (!_.isNil(found)) {
          return {
            ...x,
            defaultOperation: found?.operation,
            defaultValue: found?.value,
          };
        } else {
          return x;
        }
      });

      return _.map(initialFilters, function (o) {
        const qOwner = o?.owner;

        if (!_.isNil(o) && _.some(coordinationFilters, { owner: qOwner })) {
          const coordFilterToSet = _.find(coordinationFilters, {
            owner: qOwner,
          });

          if (!_.isNil(coordFilterToSet)) {
            return {
              ...o,
              value: coordFilterToSet.value,
              operation: Constants.SEARCH_CRITERIA_EQUAL,
              defaultValue: coordFilterToSet.value,
              defaultOperation: Constants.SEARCH_CRITERIA_EQUAL,
              canRemove: false,
              blockInteraction: true,
            };
          } else {
            return o;
          }
        } else {
          return o;
        }
      });
    } else if (
      !_.isNil(queryFilters) &&
      !_.isEmpty(queryFilters) &&
      _.isArray(queryFilters)
    ) {
      return data?.filters.map((x) => {
        const found = _.find(queryFilters, {
          owner: x?.owner,
          field: x?.field,
        });
        if (!_.isNil(found)) {
          return {
            ...x,
            defaultOperation: found?.operation,
            defaultValue: found?.value,
          };
        } else {
          return x;
        }
      });
    } else if (
      !_.isNil(queryFilters) &&
      !_.isEmpty(queryFilters) &&
      _.isObject(queryFilters) &&
      !_.isNil(queryFilters?.queryFilters) &&
      !_.isEmpty(queryFilters?.queryFilters)
    ) {
      return data?.filters?.map((x) => {
        const found = _.find(queryFilters.queryFilters, {
          owner: x?.owner,
          field: x?.field,
        });
        if (!_.isNil(found)) {
          return {
            ...x,
            defaultOperation: found?.operation,
            defaultValue: found?.value,
          };
        } else {
          return x;
        }
      });
    } else {
      return [];
    }
  }, [data, queryFilters, coordinationFilters]);

  if (status === "error") {
    showToast(error, "gimeMeFormSchema");
    return <BadForm />;
  }

  if (status === "fetching" || status === "idle") {
    return (
      <div>
        <CircularProgress style={{ color: theme.palette.content.mainColor }} />
      </div>
    );
  }

  const handleTabChange = (event, newTab) => {
    changeSelectedReportTab({ key: data?.key, newTab });
  };

  function handleQueryFilters(filters) {
    setQueryFilters((current) => ({
      ...current,
      queryFilters:
        !_.isNil(current?.queryFilters) && !_.isEmpty(current?.queryFilters)
          ? current?.queryFilters.map((x) => {
              const foundInNew = _.find(filters, {
                owner: x?.owner,
                field: x?.field,
              });
              if (!_.isNil(foundInNew)) {
                return {
                  ...x,
                  ...foundInNew,
                };
              } else {
                return x;
              }
            })
          : filters,
    }));
  }

  function handleOpenModalForm(params) {
    const {
      open,
      ukey,
      idFormRow,
      pathRow,
      defaultOperationRow,
      overrideOpsRow,
      useUkey,
    } = params;
    if (useUkey === false) {
      setOpenFormWithValues({
        ...params,
        path: pathRow,
        idForm: idFormRow,
        overrideOps: overrideOpsRow,
        operation: defaultOperationRow,
      });
    } else {
      const path = pathRow || data?.sections?.[selectedTab]?.path;
      const idForm = idFormRow || data?.sections?.[selectedTab]?.idForm;
      const defaultOperationToSet =
        defaultOperationRow ||
        data?.sections?.[selectedTab]?.defaultOperation ||
        null;
      const overrideOpsToSet =
        overrideOpsRow || data?.sections?.[selectedTab]?.overrideOps || null;
      setOpenModal(open);
      setUkey(ukey);
      setActualForm(idForm);
      setActualPath(path);
      setActualDefaultOperation(defaultOperationToSet);
      setActualOverrideOps(overrideOpsToSet);
    }
  }

  function handleRefreshCurrent() {
    setQueryFilters((current) => ({ ...current }));
  }

  function handleBackClick() {
    setOpenFormWithValues(Constants.INITIAL_OPEN_EXTERNAL_FORM_STATE);
  }

  const globalDisableSelectAll = data?.globalDisableSelectAll;
  const globalDisableSelectRow = data?.globalDisableSelectRow;

  return (
    <>
      <ToastContainerImpl />
      <ProcessHistoryDialog />
      {openModal && ukey && (
        <ExternalFormContainer
          openModal={openModal}
          handleOpenModalForm={handleOpenModalForm}
          ukey={ukey}
          operation={actualDefaultOperation || Constants.FORM_OPERATION_VIEW}
          path={actualPath}
          idForm={actualForm}
          overrideOps={actualOverrideOps}
        />
      )}

      {openFormWithValues.open && (
        <ExternalFormContainerValues
          openModal={openFormWithValues.open}
          handleOpenModalForm={handleBackClick}
          operation={actualDefaultOperation || Constants.FORM_OPERATON_EDIT}
          path={openFormWithValues.path}
          idForm={openFormWithValues.idForm}
          values={openFormWithValues.values}
          overrideOps={openFormWithValues.overrideOps}
        />
      )}

      <div className={classes.contentContainer}>
        {data && data?.filters && (
          <FilterFieldsContainer
            availableFilters={availableFilters}
            handleRefresh={(f) => handleQueryFilters(f)}
          />
        )}
        <div className={classes.queryContainer}>
          {_.size(sortedSections) > 1 ? (
            <div
              id={`${data?.key}-report-tab-container`}
              className={classes.tabsContainer}
            >
              <Tabs
                TabIndicatorProps={{
                  style: {
                    background: theme.palette.content.mainColor,
                    borderTopLeftRadius: 50,
                    borderBottomLeftRadius: 50,
                  },
                }}
                orientation="horizontal"
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                style={{ width: "70%" }}
              >
                {sortedSections?.map((section) => {
                  return <Tab key={section.name} label={section.label} />;
                })}
              </Tabs>
            </div>
          ) : null}

          <div className={classes.resultContainer}>
            <Paper
              className={classes.paperContainer}
              elevation={0}
              square={true}
            >
              <ExternalResultTable
                key={uuid()}
                title={data?.name}
                columns={
                  !_.isNil(data)
                    ? _.unionBy(data?.sections[selectedTab].columns, "field")
                    : []
                }
                handleOpenModalForm={handleOpenModalForm}
                selectedTab={selectedTab}
                path={data?.sections[selectedTab].path}
                idForm={data?.sections[selectedTab].idForm}
                keyTable={`${data?.key}-${selectedTab}`}
                keyReport={data?.key}
                jsonHiddenColums={
                  !_.isNil(data) &&
                  !_.isNil(data?.sections[selectedTab].columns) &&
                  _.isArray(data?.sections[selectedTab].columns)
                    ? data?.sections[selectedTab].columns
                        .filter(
                          (x) => x?.hidden === true || x?.isVisible === false
                        )
                        .map((c) => c.field)
                    : []
                }
                queryFilters={queryFilters}
                availableFilters={availableFilters}
                isMaterializedQuery={
                  data?.sections[selectedTab]?.isMaterializedQuery || false
                }
                overrideMaxExcelRows={
                  data?.sections[selectedTab]?.overrideMaxExcelRows || null
                }
                handleRefreshCurrent={handleRefreshCurrent}
                historyBtnFrontConfig={
                  data?.sections[selectedTab]?.historyBtnFrontConfig
                }
                handleOpenProcessHistoryDialog={handleOpenProcessHistoryDialog}
                actions={data?.sections[selectedTab]?.actions || []}
                globalActions={data?.globalActions}
                coordinationFilters={coordinationFilters}
                disableSelectAll={
                  globalDisableSelectAll === true ||
                  data?.sections[selectedTab]?.sectionDisableSelectAll === true
                }
                disableSelectRow={
                  globalDisableSelectRow === true ||
                  data?.sections[selectedTab]?.sectionDisableSelectRow === true
                }
              />
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
}
