import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import { isNil } from "lodash";

export const useStyles = makeStyles((theme) => ({
  headCell: {
    ...theme.palette.tableNew.header,
  },
  headFilterCell: {
    backgroundColor: theme.palette.background.paper,
  },
  iconExternal: {
    color: "yellowgreen",
    fontSize: "0.95rem",
    width: "auto",
    height: "auto",
    padding: 1,
  },
  loadingTable: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#fbfbfb",
    backgroundSize: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: 0.7,
    transform: "scale(1.01)",
    borderRadius: 10,
  },
  loader: {
    position: "absolute",
    top: "80%",
    right: "50%",
  },
  loaderData: {
    position: "absolute",
    top: "50%",
    right: "50%",
  },
  paperContent: {
    position: "relative",
  },
}));

export const Styles = styled.div`
  padding: 1rem;
  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow: auto;
  }

  .tableWrap thead tr:nth-child(1) th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .tableWrap thead tr:nth-child(2) th {
    position: sticky;
    top: 36px;
    z-index: 1;
  }

  .tableContainer {
    max-height: ${({ devicePixelRatio, wd }) =>
      !isNil(devicePixelRatio) && devicePixelRatio > 1 && wd?.width > 1460
        ? `calc(${65 / devicePixelRatio}vh - 60px)`
        : "calc(65vh - 60px)"};
  }

  .table {
    overflow-x: auto;
  }

  .pagination {
    padding: 0.5rem;
  }

  .checkbox {
    color: #b1b1b1;
    padding: 5px;
  }

  .Mui-checked {
    color: #66bb6a;
  }
`;

export const StyledInput = withStyles(() => ({
  input: {
    padding: "5px 4px",
  },
}))(Input);
